import { getRequest } from "./api";

export const getUsers = async (page, pageSize, searchText) => {
  var path = "admin/user";
  if (searchText && searchText !== "") {
    path = `${path}/search`;
  }
  path = `${path}?page=${page}&pageSize=${pageSize}&sortBy=username`;
  if (searchText && searchText !== "") {
    path = `${path}&text=${searchText}`;
  }

  const response = await getRequest(path, true);
  return response;
};

export const downloadUsers = async () => {
  const response = await getRequest(`admin/user/export`, true, true);
  return response;
};
