import React, { useEffect, useRef, useState } from "react";

import SectionHeader from "../../../../components/section/SectionHeader";
import SectionTitle from "../../../../components/section/SectionTitle";
import PrimaryButton from "../../../../components/button/PrimaryButton";
import CampaignTable from "../../../../components/coupons/campaigns/CampaignTable";
import { paginationOffset } from "../../../../helpers/pagination";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  deleteCampaign,
  downloadCampaignCoupons,
  getCampaigns,
} from "../../../../services/coupons/campaign";
import FormModal from "../../../../components/modal/FormModal";
import ConfirmDialog from "../../../../components/dialog/ConfirmDialog";
import AlertDialog from "../../../../components/dialog/AlertDialog";
import AddCampaignForm from "../../../../components/coupons/campaigns/AddCampaignForm";

function CouponCampaign() {
  const [campaigns, setCampaigns] = useState([]);
  const [campaignResponse, setCampaignResponse] = useState(null);
  const [showArchiveCampaigns, setShowArchiveCamgaigns] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [campaignToEdit, setCampaignToEdit] = useState(null);

  const [alertMessage, setAlertMessage] = useState("");

  const [campaignToDelete, setCampaignToDelete] = useState(null);
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const { page: pageNum, status, campaignId } = params;

  const link = useRef();

  useEffect(() => {
    if (location.pathname.lastIndexOf("/coupon-campaign/create") !== -1) {
      setShowAddForm(true);
    } else if (
      location.pathname.indexOf("/coupon-campaign") !== -1 &&
      location.pathname.indexOf("/edit") !== -1
    ) {
      // show form to edit the campaign
      // find the campaign having id equals to campaignId
      const _campaign = campaigns.find((c) => c.id == campaignId);
      if (_campaign === undefined) {
        navigate(-1);
      } else {
        setCampaignToEdit(_campaign);
        setShowAddForm(true);
      }
    } else if (
      location.pathname.indexOf("/coupon-campaign") !== -1 &&
      location.pathname.indexOf("/delete") !== -1
    ) {
      const _campaign = campaigns.find((c) => c.id == campaignId);
      if (_campaign === undefined) {
        navigate(-1);
      } else {
        setCampaignToDelete(_campaign);
        setShowDeleteConfirmation(true);
      }
    } else if (location.pathname.indexOf("/download") !== -1) {
      handleCampaignDownload();
    }

    return () => {
      setCampaignToEdit(null);
      setCampaignToDelete(null);
      setShowAddForm(false);
    };
  }, [location]);

  useEffect(() => {
    // no need to fetch if create, edit, delete page
    if (
      location.pathname.indexOf("/edit") === -1 &&
      location.pathname.indexOf("/delete") === -1 &&
      location.pathname.indexOf("/create") == -1 &&
      location.pathname.indexOf("/download") == -1
    ) {
      (async () => {
        var showArchived = false;
        if (status != undefined && status == "inactive") {
          showArchived = true;
        }
        setShowArchiveCamgaigns(showArchived);

        const response = await getCampaigns(showArchived, pageNum);
        setCampaignResponse(response);
        setCampaigns(response.data);
      })();
    }
  }, [params]);

  const handleAddNew = () => {
    navigate("/coupon-campaign/create");
  };

  const handleConfirmationDismiss = () => {
    setShowDeleteConfirmation(false);
    navigate(-1);
  };

  const handleDeleteCampaign = async () => {
    try {
      const response = await deleteCampaign(campaignId);
      setAlertMessage(response.message);
      setShowAlertDialog(true);
    } catch (err) {
      console.log(err);
    } finally {
      setShowDeleteConfirmation(false);
    }
  };

  const handleAlertDismiss = () => {
    setShowAlertDialog(false);
    navigate(-1);
  };

  // dismiss the form modal
  const dismissAddForm = (addedCampaign) => {
    setShowAddForm(false);
    navigate("/coupon-campaign", { replace: true });
  };

  // download campaign
  const handleCampaignDownload = async () => {
    try {
      const response = await downloadCampaignCoupons(campaignId);
      const href = window.URL.createObjectURL(response);

      link.current.download = `campaign_${campaignId}_coupon_${Date.now()}.csv`;
      link.current.href = href;

      link.current.click();

      navigate("/coupon-campaign", { replace: true });
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white p-2">
        <div className="rounded-t mb-0 px-4 py-3 pb-8 border-0">
          <SectionHeader>
            <SectionTitle title={"Campaigns"} />

            <PrimaryButton title={"Add New"} onClick={handleAddNew} />
          </SectionHeader>
        </div>

        <CampaignTable
          campaigns={campaigns}
          offset={paginationOffset(campaignResponse?.metadata)}
          onDelete={() => console.log("delete")}
          onArchive={() => console.log("archive")}
          isArchive={showArchiveCampaigns}
        />
      </div>

      <FormModal
        hidden={!showAddForm}
        title={`${campaignToEdit === null ? "Add" : "Update"} Campaign`}
        onDismiss={dismissAddForm}
      >
        <AddCampaignForm campaign={campaignToEdit} onDismiss={dismissAddForm} />
      </FormModal>

      {campaignToDelete !== null ? (
        <ConfirmDialog
          title="Delete Campaign?"
          message={`Are your sure you want to delete ${campaignToDelete.title}?`}
          visible={showDeleteConfirmation}
          onAccept={handleDeleteCampaign}
          onDismiss={handleConfirmationDismiss}
        />
      ) : null}

      <AlertDialog
        message={alertMessage}
        visible={showAlertDialog}
        onDismiss={handleAlertDismiss}
      />

      {location.pathname.indexOf("/download") !== -1 ? (
        <a role="button" ref={link} href="#" />
      ) : null}
    </>
  );
}

export default CouponCampaign;
