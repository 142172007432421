import React, { useState } from "react";
import SectionHeader from "../../../components/section/SectionHeader";
import SectionTitle from "../../../components/section/SectionTitle";
import LoadingIcon from "../../../assets/LoadingIcon";
import ConfirmDialog from "../../../components/dialog/ConfirmDialog";
import { postRequest } from "../../../services/api";
import { isEmpty } from "../../../helpers/validator";

function SendPushNotification() {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [selectedPlatform, setSelectedPlatform] = useState("all");
  const [errorMessage, setErrorMessage] = useState([]);
  const [successMessage, setSuccessMessage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handlePlatformChange = (value) => {
    setSelectedPlatform(value);
  };

  const handleSendNotification = () => {
    setErrorMessage(null);

    let errors = [];
    if (isEmpty(title)) {
      errors.push("Please enter the notification title.");
    }
    if (isEmpty(message)) {
      errors.push("Please enter the notification message.");
    }

    if (errors.length > 0) {
      setErrorMessage(errors);
      return;
    }

    setShowConfirmation(true);
  };

  const sendNotification = async () => {
    setErrorMessage(null);
    setSuccessMessage(null);
    setShowConfirmation(false);

    setLoading(true);
    try {
      const params = {
        title,
        message,
      };

      const response = await postRequest(
        `admin/push-notification?platform=${selectedPlatform}`,
        params,
        true
      );

      setSuccessMessage([response.message]);
      setTitle("");
      setMessage("");

      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (err) {
      setErrorMessage([err.message]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white p-2">
        <div className="rounded-t mb-0 px-4 py-3 pb-8 border-0">
          <SectionHeader>
            <SectionTitle title={"Push Notification"} />
          </SectionHeader>
        </div>

        <div className="flex-auto p-4 py-8 mb-8 mx-4 bg-slate-50">
          <form>
            {errorMessage !== null && errorMessage.length > 0 ? (
              <div className="bg-red-100 text-red-700 text-sm p-4 mx-4 mb-8 border-red-400 border-[0.5px] rounded-sm">
                <ul>
                  {errorMessage.map((msg, idx) => (
                    <li key={idx}>{msg}</li>
                  ))}
                </ul>
              </div>
            ) : null}

            {successMessage !== null && successMessage.length > 0 ? (
              <div className="bg-blue-100 text-blue-700 text-sm p-4 mx-4 mb-8 border-blue-400 border-[0.5px] rounded-sm">
                <ul>
                  {successMessage.map((msg, idx) => (
                    <li key={idx}>{msg}</li>
                  ))}
                </ul>
              </div>
            ) : null}

            <div className="flex flex-wrap">
              <div className="w-full px-4 mb-2">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Title<span>*</span>
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={title || ""}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
              </div>

              <div className="w-full px-4 mb-2">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Message<span>*</span>
                  </label>
                  <textarea
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    rows="4"
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                  ></textarea>
                </div>
              </div>

              <div className="w-full px-4 mb-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Platform<span>*</span>
                  </label>
                  <div className="flex flex-row gap-8">
                    <div>
                      <input
                        type="radio"
                        value={selectedPlatform}
                        checked={selectedPlatform === "android"}
                        onChange={() => handlePlatformChange("android")}
                      />
                      <span className="uppercase text-blueGray-600 text-xs font-bold ml-2">
                        Android
                      </span>
                    </div>
                    <div>
                      <input
                        type="radio"
                        className="uppercase text-blueGray-600 text-xs font-bold mr-2"
                        value={selectedPlatform}
                        checked={selectedPlatform === "ios"}
                        onChange={() => handlePlatformChange("ios")}
                      />
                      <span className="uppercase text-blueGray-600 text-xs font-bold ml-2">
                        iOS
                      </span>
                    </div>
                    <div>
                      <input
                        type="radio"
                        className="uppercase text-blueGray-600 text-xs font-bold mr-2"
                        value={selectedPlatform}
                        checked={selectedPlatform === "all"}
                        onChange={() => handlePlatformChange("all")}
                      />
                      <span className="uppercase text-blueGray-600 text-xs font-bold ml-2">
                        Both
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full px-4 mb-2 mt-4 flex-1 justify-end">
                <div className="relative w-full mb-3">
                  <button
                    className="flex gap-1 items-center bg-lightBlue-500 text-white bg-green-800 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none disabled:bg-gray-400 mr-1 ease-linear transition-all duration-150"
                    type="button"
                    disabled={loading}
                    onClick={handleSendNotification}
                  >
                    {loading ? <LoadingIcon /> : null}
                    <span>Send Notification</span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      {showConfirmation != null ? (
        <ConfirmDialog
          title="Send Notification?"
          visible={showConfirmation}
          onAccept={sendNotification}
          onDismiss={() => setShowConfirmation(false)}
        >
          <div>
            <h4 className="font-medium">
              Are your sure you want to send this notification?
            </h4>

            <div className="flex flex-col text-sm gap-2 mt-6">
              <div className="flex">
                <div className="w-1/3">Title:</div>
                <div>{title}</div>
              </div>
              <div className="flex">
                <div className="w-1/3">Message:</div>
                <div>{message}</div>
              </div>
              <div className="flex">
                <div className="w-1/3">Platform:</div>
                <div>
                  <span className="uppercase text-sm">{selectedPlatform}</span>
                </div>
              </div>
            </div>
          </div>
        </ConfirmDialog>
      ) : null}
    </>
  );
}

export default SendPushNotification;
