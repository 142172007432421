import React, { useState, useEffect } from "react";
import { isEmpty } from "../../../helpers/validator";
import LoadingIcon from "../../../assets/LoadingIcon";
import { formatAppDate } from "../../../helpers/date-formatter";
import { saveCampaign } from "../../../services/coupons/campaign";

function AddCampaignForm({ campaign, onDismiss }) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [startsOn, setStartsOn] = useState("");
  const [endsOn, setEndsOn] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);

  useEffect(() => {
    setTitle(campaign?.title || "");
    setDescription(campaign?.description || "");
    setStartsOn(formatAppDate(campaign?.startsOn || ""));
    setEndsOn(formatAppDate(campaign?.endsOn || ""));
    setImagePreviewUrl(campaign?.imageUrl || null);
    setActive(campaign?.active || false);

    return () => {
      campaign = null;
    };
  }, [campaign]);

  const handleSelectedImage = (e) => {
    setImageFile(e.target.files[0]);
    setImagePreviewUrl(URL.createObjectURL(e.target.files[0]));
  };

  const toggleActiveState = () => {
    setActive((prev) => !prev);
  };

  const handleSave = async () => {
    setErrorMessage(null);

    let errors = [];
    if (isEmpty(title)) {
      errors.push("Please enter the campaign name.");
    }
    if (isEmpty(startsOn)) {
      errors.push("Please enter the date to start the campaign.");
    }
    if (isEmpty(endsOn)) {
      errors.push("Please enter the date to end the campaign.");
    }

    if (errors.length > 0) {
      setErrorMessage(errors);
      return;
    }

    const formData = new FormData();
    if (imageFile !== null) {
      formData.append("image", imageFile);
    }
    if (!isEmpty(description)) {
      formData.append("description", description);
    }
    formData.append("title", title);
    formData.append("startsOn", startsOn);
    formData.append("endsOn", endsOn);

    // set active value only when editing
    if (campaign !== null) {
      formData.append("active", active);
      formData.append("id", campaign.id);
    }

    setLoading(true);
    try {
      const response = await saveCampaign(formData, campaign !== null);
      onDismiss(response);
    } catch (err) {
      setErrorMessage([err.message]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex-auto p-4 py-8 mt-8 bg-purple-50">
        <form>
          {errorMessage !== null && errorMessage.length > 0 ? (
            <div className="bg-red-100 text-red-700 text-sm p-4 mx-4 mb-8 border-red-400 border-[0.5px] rounded-sm">
              <ul>
                {errorMessage.map((msg, idx) => (
                  <li key={idx}>{msg}</li>
                ))}
              </ul>
            </div>
          ) : null}

          <div className="flex flex-wrap">
            <div className="w-full lg:w-6/12 px-4 mb-2">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Title<span>*</span>
                </label>
                <input
                  type="text"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  value={title || ""}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
            </div>

            <div className="w-full lg:w-6/12 px-4 mb-2">
              <div className="relative w-full mb-3 flex flex-col">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2 mr">
                  Campaign Image
                </label>
                <div className="flex gap-2 items-center">
                  {imagePreviewUrl != null ? (
                    <img
                      src={imagePreviewUrl}
                      className="w-10 h-auto rounded-md"
                    />
                  ) : null}
                  <input
                    accept="image/*"
                    type="file"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={handleSelectedImage}
                  />
                </div>
              </div>
            </div>

            <div className="w-full lg:w-6/12 px-4 mb-2">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Starts On<span>*</span>
                </label>
                <input
                  type="date"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  value={startsOn || ""}
                  onChange={(e) => setStartsOn(e.target.value)}
                />
              </div>
            </div>

            <div className="w-full lg:w-6/12 px-4 mb-2">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Ends On<span>*</span>
                </label>
                <input
                  type="date"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  value={endsOn || ""}
                  onChange={(e) => setEndsOn(e.target.value)}
                />
              </div>
            </div>

            <div className="w-full px-4 mb-2">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Description
                </label>
                <textarea
                  type="text"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  rows="4"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description || ""}
                ></textarea>
              </div>
            </div>

            {campaign !== null ? (
              <>
                <div className="w-full lg:w-6/12 px-4 mb-2">
                  <div className="relative w-full mb-3 flex flex-row gap-4">
                    <input
                      type="checkbox"
                      checked={active}
                      onChange={toggleActiveState}
                    />
                    <label className="block uppercase text-blueGray-600 text-xs font-bold">
                      Active
                    </label>
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4 mb-2"></div>
              </>
            ) : null}
            <div className="w-full px-4 mb-2 mt-4 flex-1 justify-end">
              <div className="relative w-full mb-3">
                <button
                  className="flex gap-1 items-center bg-lightBlue-500 text-white bg-green-800 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none disabled:bg-gray-400 mr-1 ease-linear transition-all duration-150"
                  type="button"
                  disabled={loading}
                  onClick={handleSave}
                >
                  {loading ? <LoadingIcon /> : null}
                  <span>{`${campaign === null ? "Save" : "Update"}`}</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default AddCampaignForm;
