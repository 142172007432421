import React, { useEffect, useState } from "react";
import LoadingIcon from "../../assets/LoadingIcon";
import { uploadRequest } from "../../services/api";
import { isEmpty } from "../../helpers/validator";

function AddPromoBanner({ banner, onDismiss }) {
  const [errorMessage, setErrorMessage] = useState([]);
  const [description, setDescription] = useState("");
  const [position, setPosition] = useState("");
  const [active, setActive] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setDescription(banner?.description || "");
    setImagePreviewUrl(banner?.bannerUrl || null);
    setActive(banner?.active || false);
    setPosition(banner?.position || "");

    return () => {
      banner = null;
    };
  }, [banner]);

  const handleSelectedImage = (e) => {
    setImageFile(e.target.files[0]);
    setImagePreviewUrl(URL.createObjectURL(e.target.files[0]));
  };

  const toggleActiveState = () => {
    setActive((prev) => !prev);
  };

  const handleSave = async () => {
    setErrorMessage(null);

    let errors = [];
    if (banner == null && imageFile == null) {
      errors.push("Please select the promo banner image.");
    }

    if (
      !isEmpty(`${position}`) &&
      (isNaN(parseInt(position)) || parseInt(position) < 0)
    ) {
      errors.push(
        "Please select a valid ordering number for the promo banner."
      );
    }

    if (errors.length > 0) {
      setErrorMessage(errors);
      return;
    }

    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("image", imageFile);

      if (isEmpty(description)) {
        formData.append("description", description);
      }
      if (
        !isEmpty(`${position}`) &&
        !isNaN(parseInt(position)) &&
        parseInt(position) >= 0
      ) {
        formData.append("position", position);
      }

      // set active value only when editing
      if (banner !== null) {
        formData.append("active", active);
      }

      var path = "admin/banner";
      if (banner !== null) {
        path = `${path}/${banner.id}`;
      }
      const response = await uploadRequest(path, formData, banner !== null);
      resetValues();
      onDismiss(response);
    } catch (err) {
      setErrorMessage([err.message]);
    } finally {
      setLoading(false);
    }
  };

  const resetValues = () => {
    setErrorMessage([]);
    setDescription("");
    setPosition("");
    setImageFile(null);
    setImagePreviewUrl(null);
  };

  return (
    <>
      <div className="flex-auto p-4 py-8 mt-8 bg-purple-50">
        <form>
          {errorMessage !== null && errorMessage.length > 0 ? (
            <div className="bg-red-100 text-red-700 text-sm p-4 mx-4 mb-8 border-red-400 border-[0.5px] rounded-sm">
              <ul>
                {errorMessage.map((msg, idx) => (
                  <li key={idx}>{msg}</li>
                ))}
              </ul>
            </div>
          ) : null}

          <div className="flex flex-wrap">
            <div className="w-full lg:w-6/12 px-4 mb-2">
              <div className="relative w-full mb-3 flex flex-col">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2 mr">
                  Image<span>*</span>
                </label>
                <div className="flex gap-2 items-center">
                  {imagePreviewUrl != null ? (
                    <img
                      src={imagePreviewUrl}
                      className="w-10 h-auto rounded-md"
                      alt="banner"
                    />
                  ) : null}
                  <input
                    accept="image/*"
                    type="file"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={handleSelectedImage}
                  />
                </div>
                <div>
                  <span className="text-xs italic">
                    (use jpg or png with dimension of 1000x1000 or 1000x300 px,
                    max 300kb)
                  </span>
                </div>
              </div>

              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Position
                </label>
                <input
                  type="text"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  value={position || ""}
                  onChange={(e) => setPosition(e.target.value)}
                />
                <div>
                  <span className="text-xs italic">
                    (use a number between 1 and 5000)
                  </span>
                </div>
              </div>

              {banner !== null ? (
                <>
                  <div className="relative w-full mb-3 flex flex-row gap-4 mt-8">
                    <input
                      type="checkbox"
                      checked={active}
                      onChange={toggleActiveState}
                    />
                    <label className="block uppercase text-blueGray-600 text-xs font-bold">
                      Active
                    </label>
                  </div>
                </>
              ) : null}
            </div>

            <div className="w-full lg:w-6/12 px-4 mb-2">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Description
                </label>
                <textarea
                  type="text"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  rows="5"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description || ""}
                ></textarea>
              </div>
            </div>

            <div className="w-full px-4 mb-2 mt-4 flex-1 justify-end">
              <div className="relative w-full mb-3">
                <button
                  className="flex gap-1 items-center bg-lightBlue-500 text-white bg-green-800 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none disabled:bg-gray-400 mr-1 ease-linear transition-all duration-150"
                  type="button"
                  disabled={loading}
                  onClick={handleSave}
                >
                  {loading ? <LoadingIcon /> : null}
                  <span>{"Save"}</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default AddPromoBanner;
