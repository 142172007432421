import React from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";

function MenuToggleButton({ onClick, isExpanded }) {
  return (
    <button type="button" className="px-1 py-2" onClick={onClick}>
      {isExpanded == true ? (
        <ChevronUpIcon className="w-4 h-4 text-gray-200" />
      ) : (
        <ChevronDownIcon className="w-4 h-4 text-gray-200" />
      )}
    </button>
  );
}

export default MenuToggleButton;
