import { createContext, useReducer } from "react";
import authReducer from "./AuthReducer";
import { getToken, getUser } from "./auth-storage";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const token = getToken();
  const user = getUser();

  const initialState = {
    token: token ?? null,
    user: user ?? null,
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  const saveUserLogin = (token, user) => {
    dispatch({
      type: "USER_LOGIN",
      payload: {
        token,
        user,
      },
    });
  };

  const logoutUser = () => {
    dispatch({
      type: "USER_LOGOUT",
    });
  };

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        user: state.user,
        saveUserLogin,
        logoutUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
