import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

function OfferProductItemSortable({ product }) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: product.id });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
    touchAction: "none",
  };

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={style}
      className="w-full p-2 mb-4 bg-gray-50 flex items-center gap-4 rounded-md shadow-md"
    >
      <img src={product.imageUrl} className="w-16 h-16 rounded-full block" />
      <div>{product.name}</div>
    </div>
  );
}

export default OfferProductItemSortable;
