export const formatAppDate = (dateStr) => {
  let dateObj = new Date(dateStr);
  const year = dateObj.getFullYear();
  let month = dateObj.getMonth() + 1;
  if (month < 9) {
    month = `0${month}`;
  }
  let date = dateObj.getDate();
  if (date < 9) {
    date = `0${date}`;
  }
  return `${year}-${month}-${date}`;
};
