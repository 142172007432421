import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";

import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";

import CampaignPrizeUserTable from "../../../../components/coupons/prizes/CampaignPrizeUserTable";
import PaginatedView from "../../../../components/pagination/PaginatedView";
import Dropdown from "../../../../components/dropdown/Dropdown";

import {
  getCampaignPrize,
  getCampaignPrizeUsers,
} from "../../../../services/coupons/campaign";
import { formatAppDate } from "../../../../helpers/date-formatter";

const PAGE_SIZE = [10, 20, 50, 100];

function CouponPrizeUsers() {
  const [prizeUsers, setPrizeUsers] = useState([]);
  const [prizeUsersMetadata, setPrizeUsersMetadata] = useState([]);
  const [campaignPrize, setCampaignPrize] = useState(null);
  const [pageSize, setPageSize] = useState(10);

  const { id: prizeId, page: pageNum } = useParams();

  useEffect(() => {
    if (prizeId) {
      var page = 0;
      if (!isNaN(pageNum)) {
        page = pageNum - 1;
      }
      fetch(page, pageSize);
    }
  }, [pageNum, pageSize]);

  const fetch = async (page, pageSize) => {
    try {
      const response = await getCampaignPrizeUsers(prizeId, page, pageSize);
      const prizeResponse = await getCampaignPrize(prizeId);

      setPrizeUsers(response.data);
      setPrizeUsersMetadata(response.metadata);
      setCampaignPrize(prizeResponse);
    } catch (e) {
      console.log("Error " + e);
    }
  };

  const handlePageSizeChange = (e) => {
    setPageSize(e.target.value);
  };

  return (
    <>
      <div className="px-4 md:px-10 mx-auto w-full m-10">
        <div className="flex flex-wrap">
          <div className="w-full xl:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
                <div className="flex flex-col flex-wrap items-center">
                  <div className="relative w-full max-w-full flex-grow flex-1">
                    <h6 className="uppercase text-blueGray-400 mb-1 text-xs font-semibold">
                      Overview
                    </h6>
                    <div className="rounded-t mb-4 py-3 border-0 border-b-2 border-b-gray-200">
                      <div className="relative w-full max-w-full flex-grow flex-1">
                        <h2 className="text-xl font-semibold">
                          {campaignPrize?.title ?? ""}
                        </h2>
                      </div>
                    </div>

                    <div>
                      {campaignPrize === null ? (
                        <span>Campaign prize not found.</span>
                      ) : (
                        <div className="block w-full overflow-x-auto">
                          <table className="items-center w-full bg-transparent border-collapse">
                            <tbody>
                              {campaignPrize.imageUrl !== null ? (
                                <tr>
                                  <td colSpan={2}>
                                    <img
                                      src={campaignPrize.imageUrl}
                                      className="w-[70px] h-auto mb-6"
                                    />
                                  </td>
                                </tr>
                              ) : null}

                              {campaignPrize.description !== null ? (
                                <tr>
                                  <td colSpan={2}>
                                    <span className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                      {campaignPrize.description}
                                    </span>
                                  </td>
                                </tr>
                              ) : null}
                              <tr>
                                <th className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                  No. of users
                                </th>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                  {campaignPrize.couponCount}
                                </td>
                              </tr>

                              <tr>
                                <th className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                  Value
                                </th>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                  &euro;{campaignPrize.value}
                                </td>
                              </tr>

                              <tr>
                                <th className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                  Status
                                </th>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                  {campaignPrize.active ? "Active" : "Inactive"}
                                </td>
                              </tr>

                              <tr>
                                <th className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                  Created On
                                </th>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                  {formatAppDate(campaignPrize.createdOn)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-slate-700">
              <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full max-w-full flex-grow flex-1">
                    <h6 className="uppercase text-slate-100 mb-1 text-xs font-semibold">
                      Campaign Prize
                    </h6>
                    <h2 className="text-white text-xl font-semibold">Users</h2>
                  </div>
                  <div>
                    <Link
                      to={`/coupon-prize/${prizeId}/users/${
                        prizeUsers.length > 0 ? "edit" : "add"
                      }`}
                    >
                      <span className="mt-4 bg-green-700 px-4 py-2 rounded-md font-semibold text-sm text-white">
                        {prizeUsers.length > 0
                          ? "Modify Users"
                          : "Assign Users"}
                      </span>
                    </Link>
                  </div>

                  <Link
                    to={`/coupon-prize/${prizeId}/users/add?bulk`}
                    className="ml-2 flex flex-row gap-2 items-center bg-green-700 px-4 py-2 rounded-md font-semibold text-sm text-white"
                  >
                    <ArrowUpTrayIcon className="w-4 h-4" />
                    <span className="">CSV</span>
                  </Link>
                </div>
              </div>

              <div className="p-4 flex-auto">
                <div className="relative h-350-px">
                  {prizeUsers.length > 0 ? (
                    <>
                      <div className="mb-2 flex justify-end">
                        <select
                          onChange={handlePageSizeChange}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-slate-500 focus:border-slate-500 block w-20 mt-2 mb-0 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-slate-500 dark:focus:border-slate-500"
                        >
                          <option value="10">10</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                      <div className="block w-full p-3 bg-white rounded mb-6 xl:mb-3 shadow-lg">
                        <CampaignPrizeUserTable
                          prizeUsers={prizeUsers}
                          offset={0}
                        />

                        {prizeUsersMetadata?.totalPages > 1 ? (
                          <div className="py-2 flex justify-end my-6">
                            <PaginatedView
                              path={`/coupon-prize/${prizeId}/users/page`}
                              total={prizeUsersMetadata?.totalPages}
                              current={prizeUsersMetadata?.currentPage}
                              next={prizeUsersMetadata?.nextPage}
                              previous={prizeUsersMetadata?.previousPage}
                            />
                          </div>
                        ) : null}
                      </div>
                    </>
                  ) : (
                    <div className="text-base font-light leading-relaxed mt-0 mb-0 text-gray-200">
                      <p>No users assigned for this campaign prize.</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CouponPrizeUsers;
