import React from "react";
import MenuItem from "./MenuItem";
import { navItemList } from "./menu-items";

function Menu() {
  return (
    <>
      <div className="absolute hidden md:relative md:flex flex-col w-full md:min-w-[250px] md:w-[250px] mt-[80px] md:mt-0 duration-200 h-screen bg-green-700 text-stone-200 p-4">
        <div className="w-[90px] h-auto">
          <img src="./logo-white.png" className="hidden md:flex h-full" />
        </div>

        <div className="my-4 mx-2 mt-8">
          <ul className="md:flex-col md:min-w-full flex flex-col list-none">
            {navItemList.map((item) => (
              <MenuItem item={item} key={item.id} />
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Menu;
