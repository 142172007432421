import React from "react";
import PrimaryButton from "../button/PrimaryButton";
import SectionTitle from "../section/SectionTitle";
import DestructiveButton from "../button/DestructiveButton";
import SectionHeader from "../section/SectionHeader";

function ConfirmDialog({
  title,
  message,
  visible,
  onAccept,
  onDismiss,
  children,
}) {
  return (
    <>
      <div
        className={`${
          visible ? "flex" : "hidden"
        } w-full bg-[#00000080] h-full absolute m-0 top-0 left-0 justify-center items-center`}
      >
        <div className="min-w-40 max-w-[90%] md:max-w-[60%] h-auto bg-white rounded-md p-4 overflow-auto">
          <SectionHeader>
            <SectionTitle title={title} />
          </SectionHeader>

          <div className="flex-auto p-2  py-6 mt-4 mb-8 border-b-[1px] border-b-slate-100  border-t-[1px] border-t-slate-100">
            {message != undefined ? <div>{message}</div> : null}
            {children != undefined ? children : null}
          </div>

          <div className="flex flex-row-reverse gap-2 mt-6">
            <PrimaryButton title="Ok" onClick={onAccept} />
            <DestructiveButton title={"Close"} onClick={onDismiss} />
          </div>
        </div>
      </div>
    </>
  );
}

export default ConfirmDialog;
