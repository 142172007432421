import React from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

function FormModal({ hidden, onDismiss, title, children }) {
  return (
    <>
      <div
        className={`${
          hidden ? "hidden" : "flex"
        } w-full bg-[#00000080] h-full absolute m-0 top-0 left-0 justify-center items-center`}
      >
        <div className="w-[90%] h-[80%] md:w-[70%] md:h-[60%] md:min-w-[500px] md:min-h-[400px] bg-white rounded-md p-8 overflow-scroll">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex items-center justify-between">
              <h3 className="font-semibold text-sm uppercase text-blueGray-700">
                {title}
              </h3>

              <XMarkIcon
                className="w-6 h-6"
                onClick={onDismiss.bind(null, null)}
              />
            </div>
          </div>

          {children}
        </div>
      </div>
    </>
  );
}

export default FormModal;
