import React from "react";

function SectionHeader({ children }) {
  return (
    <div className="flex flex-wrap items-center py-2">
      <div className="relative w-full max-w-full flex-grow flex items-center justify-between">
        {children}
      </div>
    </div>
  );
}

export default SectionHeader;
