import React from "react";
import { formatAppDate } from "../../helpers/date-formatter";
import ArchiveIcon from "../../assets/ArchiveIcon";
import EditIcon from "../../assets/EditIcon";
import DeleteIcon from "../../assets/DeleteIcon";
import ActivateIcon from "../../assets/ActivateIcon";

function OfferProductTable({ products, offset, onDelete, onEdit, onArchive }) {
  return (
    <>
      <table className="w-full min-w-[640px] table-auto">
        <thead>
          <tr>
            {["sn", "image", "name", "valid until", "offer price", ""].map(
              (el) => (
                <th
                  key={el}
                  className="border-y bg-slate-50 border-blue-slate-50 py-3 px-5 text-left"
                >
                  <span className="text-[11px] font-bold uppercase text-blue-gray-400">
                    {el}
                  </span>
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody>
          {products.map((product, key) => {
            const {
              id,
              imageUrl,
              name,
              price,
              offerPrice,
              validUntil,
              active,
            } = product;

            return (
              <tr key={id}>
                <td className="py-3 px-5 border-b border-blue-gray-50 w-[60px]">
                  <span className="text-xs font-semibold text-blue-gray-600">
                    {offset && !offset.isNaN ? offset + key + 1 : key + 1}
                  </span>
                </td>
                <td className="py-3 px-5 border-b border-blue-gray-50 w-20">
                  <span className="text-xs font-semibold text-blue-gray-600">
                    <img src={imageUrl} className="w-[60px] h-auto" />
                  </span>
                </td>
                <td className="py-3 px-5 border-b border-blue-gray-50">
                  <div className="flex flex-col gap-2">
                    <span className="text-xs font-semibold text-blue-gray-600">
                      {name}
                    </span>
                  </div>
                </td>
                <td className="py-3 px-5 border-b border-blue-gray-50 w-32">
                  <span className="text-xs font-semibold text-blue-gray-600">
                    {formatAppDate(validUntil)}
                  </span>
                </td>
                <td className="py-3 px-5 border-b border-blue-gray-50 w-32">
                  <span className="text-xs font-semibold text-blue-gray-600 line-through mr-2 opacity-50">
                    {price}
                  </span>
                  <span className="text-xs font-semibold text-blue-gray-600">
                    {offerPrice}
                  </span>
                </td>
                <td className="py-3 px-5 border-b border-blue-gray-50 text-right w-32">
                  <div className=" flex flex-row gap-2">
                    <span
                      className={`text-xs font-semibold ${
                        !active ? "text-green-400" : "text-orange-400"
                      } cursor-pointer`}
                      onClick={() => onArchive(product)}
                      title={`${!active ? "Activate" : "Archive"}`}
                    >
                      {!active ? <ActivateIcon /> : <ArchiveIcon />}
                    </span>

                    <span
                      className="text-xs font-semibold text-blue-500 cursor-pointer"
                      onClick={() => onEdit(product)}
                      title="Edit"
                    >
                      <EditIcon />
                    </span>
                    <a
                      className="text-xs font-semibold  text-red-600 cursor-pointer"
                      onClick={() => onDelete(product)}
                      title="Delete"
                    >
                      <DeleteIcon />
                    </a>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default OfferProductTable;
