import React, { useEffect, useState, useRef } from "react";
import SectionHeader from "../../../components/section/SectionHeader";
import SectionTitle from "../../../components/section/SectionTitle";
import UsersTable from "../../../components/users/UsersTable";
import { paginationOffset } from "../../../helpers/pagination";
import { getRequest } from "../../../services/api";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import PaginatedView from "../../../components/pagination/PaginatedView";
import { isEmpty } from "../../../helpers/validator";
import {
  ArrowDownTrayIcon,
  MagnifyingGlassCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { downloadUsers } from "../../../services/users";

function User() {
  const [users, setUsers] = useState([]);
  const [userResponse, setUserResponse] = useState(null);
  const [searchText, setSearchText] = useState("");

  const { page: pageNum } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const link = useRef();

  useEffect(() => {
    if (searchParams.get("search") !== null) {
      setSearchText(searchParams.get("search"));
    }
    getUsers();
  }, [pageNum, searchParams]);

  useEffect(() => {
    if (location.pathname.indexOf("/download") !== -1) {
      handleUserDownload();
    }
  }, [location]);

  const getUsers = async (page = pageNum) => {
    if (isNaN(page)) {
      page = 0;
    }

    page = page - 1;
    if (page < 0) {
      page = 0;
    }

    var text = searchParams.get("search");
    if (text === undefined || text === null) {
      text = "";
    }

    var path = "admin/user";
    if (!isEmpty(text)) {
      path = `${path}/search`;
    }

    try {
      const response = await getRequest(
        `${path}?page=${
          page ?? 0
        }&pageSize=50&sortBy=userInfo.registeredOn&order=desc${
          !isEmpty(text) ? "&text=" + text : ""
        }`,
        true
      );
      setUsers(response.data);
      setUserResponse(response);
    } catch (err) {
      //console.log(err);
      console.log(err);
    }
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    handleSearch();
  };

  const handleSearch = () => {
    navigate("page/1?search=" + encodeURIComponent(searchText));
  };

  const clearSearch = () => {
    setSearchText("");
    navigate("");
  };

  const handleUserDownload = async () => {
    try {
      const response = await downloadUsers();
      const href = window.URL.createObjectURL(response);

      link.current.download = `customers_${Date.now()}.csv`;
      link.current.href = href;

      link.current.click();

      navigate("/users", { replace: true });
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white p-2">
        <div className="flex flex-row rounded-t mb-0 px-4 py-3 pb-8 border-0">
          <div className="flex w-1/2 items-end">
            <SectionHeader>
              <SectionTitle title={"Users"} />
            </SectionHeader>
          </div>

          <div className="flex  w-1/2 justify-end items-end gap-2">
            <div className="flex py-1 px-1 w-full lg:w-2/3 items-stretch border-b-2">
              <form onSubmit={handleSearchSubmit} className="flex w-full">
                <input
                  type="text"
                  name="searchText"
                  placeholder="Search"
                  className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm  focus:outline-none w-full ease-linear transition-all duration-150"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                {searchText !== "" ? (
                  <button
                    className="text-gray-300 hover:text-gray-400 font-bold uppercase text-xs px-2 py-2 rounded outline-none focus:outline-none disabled:bg-gray-400 ease-linear transition-all duration-150"
                    type="button"
                    onClick={clearSearch}
                  >
                    <XMarkIcon className="w-4 h-4 rounded-full border-gray-300 hover:text-gray-400 border-[1px]" />
                  </button>
                ) : null}
                <button
                  className="text-green-700 hover:text-green-900 font-bold uppercase text-xs px-2 py-2 rounded outline-none focus:outline-none disabled:bg-gray-400 ease-linear transition-all duration-150"
                  type="button"
                  onClick={handleSearch}
                >
                  <MagnifyingGlassCircleIcon className="w-6 h-6" />
                </button>
              </form>
            </div>
          </div>
        </div>

        <div className="flex flex-auto justify-start items-middle text-green-600">
          <Link
            to={"/users/download"}
            className="flex flex-row gap-2 text-sm mb-4 px-4 hover:underline"
          >
            <ArrowDownTrayIcon className="w-5 h-5" />{" "}
            <span>Download Users</span>
          </Link>
        </div>

        <UsersTable
          users={users}
          offset={paginationOffset(userResponse?.metadata)}
        />

        {userResponse?.metadata?.totalPages > 1 ? (
          <div className="py-2 flex justify-end my-6 flex-col items-end">
            <PaginatedView
              path={`/users/page`}
              query={searchParams.toString()}
              total={userResponse?.metadata.totalPages}
              current={userResponse?.metadata?.currentPage}
              next={userResponse?.metadata?.nextPage}
              previous={userResponse?.metadata?.previousPage}
            />
          </div>
        ) : null}
      </div>
      {location.pathname.indexOf("/download") !== -1 ? (
        <a role="button" ref={link} href="#" />
      ) : null}
    </>
  );
}

export default User;
