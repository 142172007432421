import React, { useContext, useEffect, useState } from "react";
import UserIcon from "@heroicons/react/24/outline/UserIcon";
import { Bars3Icon } from "@heroicons/react/24/outline";
import AuthContext from "../../context/auth/AuthContext";
import { isEmpty } from "../../helpers/validator";

function Navbar() {
  const [name, setName] = useState("");

  const { user } = useContext(AuthContext);

  useEffect(() => {
    let fullname = [user?.firstName, user?.lastName].join(" ");

    if (isEmpty(fullname)) {
      fullname = "User";
    }
    setName(fullname);
  }, [user]);

  return (
    <div className="flex justify-between md:justify-end p-4 shadow-lg h-20">
      <div className="md:hidden">
        <img src="/logo.png" alt="Solu Market" className="h-[95%]" />
      </div>

      <div className="flex gap-4 items-center">
        <div>{name}</div>
        <div className="w-8 h-8 p-2 rounded-full bg-slate-400">
          <UserIcon className="text-white" />
        </div>
        <Bars3Icon
          className="md:hidden w-6 h-6 cursor-pointer"
          onClick={() => console.log("toggle menu...")}
        />
      </div>
    </div>
  );
}

export default Navbar;
