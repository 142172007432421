import React from "react";

function DestructiveButton({ title, onClick }) {
  return (
    <button
      className="text-red-500 hover:text-red-600 background-transparent font-bold uppercase px-6 py-2 text-sm ease-linear transition-all duration-150"
      onClick={onClick}
    >
      {title}
    </button>
  );
}

export default DestructiveButton;
