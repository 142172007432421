export class BadRequestError extends Error {
  constructor(message) {
    super(message);

    this.name = "Bad Request";
    this.status = 400;
  }
}

export class NotAuthorizedError extends Error {
  constructor(message, statusCode) {
    super(message);

    this.name = "NotAuthorizedError"; // (2)
    this.status = statusCode;
  }
}

export class NetworkError extends Error {
  constructor(message, statusCode) {
    super(message);

    this.name = "Network error"; // (2)
    this.status = statusCode;
  }
}
