import { saveLogin, clearLogin } from "./auth-storage";

const authReducer = (state, action) => {
  switch (action.type) {
    case "USER_LOGIN":
      saveLogin(action.payload.token, action.payload.user);
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
      };

    case "USER_LOGOUT":
      clearLogin();
      return {
        ...state,
        token: null,
      };

    default:
      return state;
  }
};

export default authReducer;
