import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import SectionHeader from "../../../components/section/SectionHeader";
import SectionTitle from "../../../components/section/SectionTitle";
import {
  getUserCoupons,
  setUserCouponUsed,
} from "../../../services/coupons/campaign";
import { formatAppDate } from "../../../helpers/date-formatter";
import {
  CheckBadgeIcon,
  CheckIcon,
  MinusCircleIcon,
} from "@heroicons/react/16/solid";

function UserCoupon() {
  const [coupons, setCoupons] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    fetchUserCoupons(id);
  }, [id]);

  const fetchUserCoupons = async (id) => {
    try {
      const response = await getUserCoupons(id);
      setCoupons(response);
    } catch (e) {
      console.log(e);
    }
  };

  const handleCouponUsed = async (code) => {
    try {
      const response = await setUserCouponUsed(code);

      setCoupons((prev) => {
        var updated = [...prev];
        var index = updated.findIndex((u) => u.id === response.id);
        updated[index] = response;
        return updated;
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white p-2">
        <div className="flex flex-row rounded-t mb-0 px-4 py-3 pb-8 border-0">
          <div className="flex w-1/2 items-end">
            <SectionHeader>
              <SectionTitle title={"User Coupons"} />
            </SectionHeader>
          </div>
        </div>
        {coupons.length > 0 ? (
          <table className="w-full min-w-[640px] table-auto">
            <thead>
              <tr>
                {[
                  "sn",
                  "campaign title",
                  "prize title",
                  "valid until",
                  "coupon code",
                  "assigned on",
                  "scratched on",
                  "used on",
                  "",
                ].map((el) => (
                  <th
                    key={el}
                    className="border-y bg-slate-50 border-blue-slate-50 py-3 px-5 text-left"
                  >
                    <span className="text-[11px] font-bold uppercase text-blue-gray-400">
                      {el}
                    </span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {coupons.map((coupon, key) => {
                const {
                  id,
                  campaignTitle,
                  campaignId,
                  prizeId,
                  prizeTitle,
                  prizeDescription,
                  campaignEndsOn,
                  code,
                  assignedOn,
                  scratchedOn,
                  usedOn,
                } = coupon;

                return (
                  <tr key={id}>
                    <td className="py-3 px-5 border-b border-blue-gray-50 w-[60px]">
                      <span className="text-xs font-semibold text-blue-gray-600">
                        {key + 1}
                      </span>
                    </td>
                    <td className="py-3 px-5 border-b border-blue-gray-50 w-32">
                      <Link to={`/coupon-campaign/${campaignId}`}>
                        <span className="text-xs font-semibold text-blue-gray-600 underline">
                          {campaignTitle}
                        </span>
                      </Link>
                    </td>
                    <td className="py-3 px-5 border-b border-blue-gray-50 w-60">
                      <div className="flex flex-col gap-2">
                        <Link to={`/coupon-prize/${prizeId}/users`}>
                          <span className="text-xs font-semibold text-blue-gray-600 underline">
                            {prizeTitle}
                          </span>
                        </Link>
                        <span className="text-xs text-blue-gray-600 mr-2">
                          {prizeDescription}
                        </span>
                      </div>
                    </td>
                    <td className="py-3 px-5 border-b border-blue-gray-50">
                      <div className="flex flex-col gap-2">
                        <span className="text-xs font-semibold text-blue-gray-600">
                          {formatAppDate(campaignEndsOn)}
                        </span>
                      </div>
                    </td>
                    <td className="py-3 px-5 border-b border-blue-gray-50 w-32">
                      <span className="text-sm font-bold text-blue-gray-600">
                        {code}
                      </span>
                    </td>
                    <td className="py-3 px-5 border-b border-blue-gray-50 w-32">
                      <span className="text-xs font-semibold text-blue-gray-600 mr-2">
                        {formatAppDate(assignedOn)}
                      </span>
                    </td>
                    <td className="py-3 px-5 border-b border-blue-gray-50 w-36">
                      <span className="text-xs font-semibold text-blue-gray-600 mr-2">
                        {scratchedOn !== null
                          ? formatAppDate(scratchedOn)
                          : "-"}
                      </span>
                    </td>
                    <td className="py-3 px-5 border-b border-blue-gray-50 w-32">
                      <span className="text-xs font-semibold text-blue-gray-600 mr-2">
                        {usedOn !== null ? formatAppDate(usedOn) : "-"}
                      </span>
                    </td>
                    <td className="py-3 px-5 border-b border-blue-gray-50 text-right w-32">
                      <div className=" flex flex-row gap-2">
                        {usedOn === null ? (
                          <span
                            className={`text-xs font-semibold cursor-pointer text-green-400`}
                            title="mark used"
                          >
                            <CheckIcon
                              className="w-5 h-5"
                              onClick={handleCouponUsed.bind(null, code)}
                            />
                          </span>
                        ) : (
                          <span
                            className={`text-xs font-semibold cursor-pointer text-red-400`}
                            title="coupon used"
                          >
                            <MinusCircleIcon className="w-4 h-4" />
                          </span>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div className="flex flex-row rounded-t mb-0 px-4 py-3 pb-8 border-0 text-sm text-red-600">
            No coupon has been assigned to the user.
          </div>
        )}
      </div>
    </>
  );
}

export default UserCoupon;
