import React, { useEffect, useState } from "react";
import {
  PencilSquareIcon,
  TicketIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import FormModal from "../../../../components/modal/FormModal";
import ConfirmDialog from "../../../../components/dialog/ConfirmDialog";
import AlertDialog from "../../../../components/dialog/AlertDialog";

import {
  deleteCampaignPrize,
  getCampaign,
  getCampaignPrizes,
} from "../../../../services/coupons/campaign";
import { formatAppDate } from "../../../../helpers/date-formatter";
import AddCampaignPrizeForm from "../../../../components/coupons/prizes/AddCampaignPrizeForm";

function contains(str, substr) {
  return str.indexOf(substr) !== -1;
}

function containsLast(str, substr) {
  return str.lastIndexOf(substr) !== -1;
}

function CouponCampaignDetails() {
  const [campaign, setCampaign] = useState(null);
  const [campaignPrizes, setCampaignPrizes] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [prizeToEdit, setPrizeToEdit] = useState(null);

  const [prizeToDelete, setPrizeToDelete] = useState(null);
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const navigate = useNavigate();
  const { id, prizeId } = useParams();
  const location = useLocation();

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    const path = location.pathname;

    if (containsLast(path, "/prize/create") && id !== null) {
      setPrizeToEdit(null);
      setShowAddForm(true);
    } else if (contains(path, `/coupon-campaign/${id}/prize`)) {
      const _prize = campaignPrizes.find((p) => p.id == prizeId);
      if (_prize === undefined) {
        navigate(`/coupon-campaign/${id}`, { replace: true });
      } else {
        if (containsLast(path, "/edit")) {
          setPrizeToEdit(_prize);
          setShowAddForm(true);
        } else if (containsLast(path, "/delete")) {
          setPrizeToDelete(_prize);
          setShowDeleteConfirmation(true);
        }
      }
    } else {
      setShowAddForm(false);
    }
  }, [location, id, prizeId]);

  const fetch = async () => {
    try {
      const response = await getCampaign(id);
      setCampaign(response);

      const prizesResponse = await getCampaignPrizes(id);
      setCampaignPrizes(prizesResponse);
    } catch (e) {
      //console.log(e.message);
    }
  };

  const handleDeleteCampaignPrize = async () => {
    try {
      const response = await deleteCampaignPrize(prizeId);
      setAlertMessage(response.message);
      setShowAlertDialog(true);
    } catch (err) {
    } finally {
      setShowDeleteConfirmation(false);
    }
  };

  const handleConfirmationDismiss = () => {
    setShowDeleteConfirmation(false);
    navigate(-1);
  };

  const handleAlertDismiss = () => {
    setShowAlertDialog(false);
    fetch();
    navigate(-1);
  };

  const handleAddPrizes = () => {};

  // dismiss the form modal
  const dismissAddForm = () => {
    setShowAddForm(false);
    fetch();
    //navigate(`/coupon-campaign/${id}`, { replace: true });
    navigate(-1);
  };

  return (
    <>
      <div className="px-4 md:px-10 mx-auto w-full m-10">
        <div className="flex flex-wrap">
          <div className="w-full xl:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
                <div className="flex flex-col flex-wrap items-center">
                  <div className="relative w-full max-w-full flex-grow flex-1">
                    <h6 className="uppercase text-blueGray-400 mb-1 text-xs font-semibold">
                      Overview
                    </h6>
                    <div className="rounded-t mb-0 py-3 border-0">
                      <div className="relative w-full max-w-full flex-grow flex-1">
                        <h2 className="text-xl font-semibold">
                          {campaign?.title ?? ""}
                        </h2>
                      </div>
                    </div>

                    <div>
                      {campaign === null ? (
                        <span>Campaign not found.</span>
                      ) : (
                        <div className="block w-full overflow-x-auto">
                          <table className="items-center w-full bg-transparent border-collapse">
                            <tbody>
                              {campaign.description !== null ? (
                                <tr>
                                  <td colSpan={2}>
                                    <span className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                      {campaign.description}
                                    </span>
                                  </td>
                                </tr>
                              ) : null}
                              <tr>
                                <th className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                  Starts On
                                </th>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                  {formatAppDate(campaign.startsOn)}
                                </td>
                              </tr>

                              <tr>
                                <th className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                  Ends On
                                </th>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                  {formatAppDate(campaign.endsOn)}
                                </td>
                              </tr>

                              <tr>
                                <th className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                  Status
                                </th>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                  {campaign.active ? "Active" : "Inactive"}
                                </td>
                              </tr>

                              <tr>
                                <th className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                  Created On
                                </th>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                  {formatAppDate(campaign.createdOn)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-4 flex-auto">
                <div className="relative h-350-px"></div>
              </div>
            </div>
          </div>

          <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-slate-700">
              <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full max-w-full flex-grow flex-1">
                    <h6 className="uppercase text-slate-100 mb-1 text-xs font-semibold">
                      Campaign
                    </h6>
                    <h2 className="text-white text-xl font-semibold">Prizes</h2>
                  </div>
                  <div>
                    <button
                      className="mt-4 bg-green-700 px-4 py-2 rounded-md font-semibold text-sm text-white"
                      onClick={handleAddPrizes}
                    >
                      <Link to={`/coupon-campaign/${id}/prize/create`}>
                        Add Prize
                      </Link>
                    </button>
                  </div>
                </div>
              </div>

              <div className="p-4 flex-auto">
                <div className="relative h-350-px">
                  {campaignPrizes.length > 0 ? (
                    <>
                      <div className="block w-full p-3 bg-white rounded mb-6 xl:mb-3 shadow-lg">
                        <table className="items-center w-full bg-transparent border-collapse">
                          <thead>
                            <tr>
                              <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-gray-50 text-blueGray-500 border-gray-100"></th>
                              <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-gray-50 text-blueGray-500 border-gray-100"></th>
                              <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-gray-50 text-blueGray-500 border-gray-100">
                                Value
                              </th>
                              <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-gray-50 text-blueGray-500 border-gray-100">
                                Users
                              </th>
                              <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-gray-50 text-blueGray-500 border-gray-100">
                                Status
                              </th>
                              <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-gray-50 text-blueGray-500 border-gray-100"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {campaignPrizes.map((prize) => (
                              <tr key={prize.id}>
                                <td className="border-t-0 w-16 h-16 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap">
                                  <img
                                    src={prize.imageUrl}
                                    className="rounded-full bg-gray-100 w-12 h-12 border"
                                  />
                                </td>
                                <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                  <div>{prize.title}</div>
                                  <div>{prize.description}</div>
                                </td>
                                <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                  &euro;{prize.value}
                                </td>
                                <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                  <Link to={`/coupon-prize/${prize.id}/users`}>
                                    <div className="flex items-center">
                                      <span className="mr-2">
                                        <TicketIcon className="w-3 h-3" />
                                      </span>
                                      <div className="relative w-full underline">
                                        {prize.couponCount}
                                      </div>
                                    </div>
                                  </Link>
                                </td>
                                <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                  <span
                                    className={`block mx-auto w-3 h-3 shadow-md shadow-gray-300 rounded-full ${
                                      prize.active
                                        ? "bg-green-500"
                                        : "bg-red-500"
                                    }`}
                                    title={prize.active ? "Active" : "Inactive"}
                                  ></span>
                                </td>
                                <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                  <div className="flex flex-row gap-2 justify-end">
                                    <span
                                      className="text-blue-500 cursor-pointer"
                                      title="Edit"
                                    >
                                      <Link
                                        to={`/coupon-campaign/${id}/prize/${prize.id}/edit`}
                                      >
                                        <PencilSquareIcon className="w-4 h-4" />
                                      </Link>
                                    </span>
                                    <span
                                      className="text-red-600 cursor-pointer"
                                      title="Delete"
                                    >
                                      <Link
                                        to={`/coupon-campaign/${id}/prize/${prize.id}/delete`}
                                      >
                                        <TrashIcon className="w-4 h-4" />
                                      </Link>
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) : (
                    <div className="text-base font-light leading-relaxed mt-0 mb-0 text-gray-200">
                      <p>No prizes configured for this campaign.</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FormModal
        hidden={!showAddForm}
        title={`${prizeToEdit === null ? "Add" : "Update"} Campaign Prizes`}
        onDismiss={dismissAddForm}
      >
        <AddCampaignPrizeForm
          prize={prizeToEdit}
          campaignId={id}
          onDismiss={dismissAddForm}
        />
      </FormModal>

      {prizeToDelete !== null ? (
        <ConfirmDialog
          title="Delete Campaign Prize?"
          message={`Are your sure you want to delete ${prizeToDelete.title}?`}
          visible={showDeleteConfirmation}
          onAccept={handleDeleteCampaignPrize}
          onDismiss={handleConfirmationDismiss}
        />
      ) : null}

      <AlertDialog
        message={alertMessage}
        visible={showAlertDialog}
        onDismiss={handleAlertDismiss}
      />
    </>
  );
}

export default CouponCampaignDetails;
