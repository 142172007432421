import React from "react";

function TableCell({ className, colspan, children }) {
  return (
    <td
      colSpan={colspan}
      className={`py-3 px-5 border-b border-gray-200 text-xs font-semibold text-gray-800 ${className}`}
    >
      {children}
    </td>
  );
}

export default TableCell;
