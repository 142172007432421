import React, { useContext, useEffect, useState } from "react";
import { isEmpty } from "../../helpers/validator";
import { postRequest } from "../../services/api";
import LoadingIcon from "../../assets/LoadingIcon";
import AuthContext from "../../context/auth/AuthContext";
import { useNavigate } from "react-router-dom";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);

  const { token, saveUserLogin } = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (token !== null) {
      navigate("/");
    }
  }, [token]);

  const handleSubmit = async () => {
    setErrorMessage([]);
    let errors = [];
    if (isEmpty(email)) {
      errors.push("Please enter the username.");
    }
    if (isEmpty(password)) {
      errors.push("Please enter the password.");
    }

    if (errors.length > 0) {
      setErrorMessage(errors);
      return;
    }

    setLoading(true);
    try {
      const response = await postRequest("admin/login", {
        email,
        password,
      });
      saveUserLogin(response.token, response.user);
    } catch (err) {
      setErrorMessage([err.message]);
    } finally {
      setLoading(false);
    }
  };

  return token !== null ? (
    <></>
  ) : (
    <div className="relative w-full h-full py-40 min-h-screen bg-slate-200">
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-50 border-0">
              <div className="flex-auto px-4 lg:px-10 py-10 ">
                <div className="text-slate-400 text-center mt-3 mb-6 font-bold">
                  <span className="uppercase text-xl text-gray-900">Login</span>
                </div>
                <form>
                  {errorMessage !== null && errorMessage.length > 0 ? (
                    <div className="bg-red-100 text-red-700 text-sm p-4 mb-8 border-red-400 border-[0.5px] rounded-sm">
                      <ul>
                        {errorMessage.map((msg, idx) => (
                          <li key={idx}>{msg}</li>
                        ))}
                      </ul>
                    </div>
                  ) : null}
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                      Username
                    </label>
                    <input
                      type="email"
                      className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Username"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="relative w-full my-6">
                    <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                      Password
                    </label>
                    <input
                      type="password"
                      className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  {/* 
                  <div>
                    <label className="inline-flex items-center cursor-pointer mt-2">
                      <input
                        id="customCheckLogin"
                        type="checkbox"
                        className="form-checkbox border-0 rounded text-slate-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                      />
                      <span className="ml-2 text-sm font-semibold text-slate-600">
                        Remember me
                      </span>
                    </label>
                  </div>
                  */}
                  <div className="text-center mt-8">
                    <button
                      className="flex gap-1 justify-center items-center bg-green-600 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg disabled:bg-gray-400  mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="button"
                      onClick={handleSubmit}
                      disabled={loading}
                    >
                      {loading ? <LoadingIcon /> : null}
                      Login
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
