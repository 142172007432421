import React from "react";
import LoadingIcon from "../../assets/LoadingIcon";

function PrimaryButton({ title, onClick, isBusy }) {
  return (
    <button
      className={`${
        isBusy === true ? "opacity-60" : ""
      } bg-green-700 text-white  active:bg-green-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:bg-green-800 hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150`}
      onClick={onClick}
      disabled={isBusy === true}
    >
      <div className="flex flex-row">
        {isBusy === true ? <LoadingIcon color="text-gray-700" /> : null}
        {title}
      </div>
    </button>
  );
}

export default PrimaryButton;
