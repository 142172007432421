import React, { useEffect, useState } from "react";
import PaginationView from "../../../components/pagination/PaginationView";
import { deleteRequest, getRequest, patchRequest } from "../../../services/api";
import OfferProductTable from "../../../components/offer-products/OfferProductTable";
import AddOfferProduct from "../../../components/offer-products/AddOfferProduct";
import FormModal from "../../../components/modal/FormModal";
import ConfirmDialog from "../../../components/dialog/ConfirmDialog";
import AlertDialog from "../../../components/dialog/AlertDialog";
import PrimaryButton from "../../../components/button/PrimaryButton";
import SectionTitle from "../../../components/section/SectionTitle";
import SectionHeader from "../../../components/section/SectionHeader";
import { paginationOffset } from "../../../helpers/pagination";
import Dropdown from "../../../components/dropdown/Dropdown";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import PaginatedView from "../../../components/pagination/PaginatedView";

const OfferTypeDropdownItem = [
  { id: 1, title: "Show Active", path: "" },
  { id: 2, title: "Show Archived", path: "inactive" },
  { id: 3, title: "Show Expired", path: "expired" },
];

function OfferProduct() {
  const [products, setProducts] = useState([]);
  const [productResponse, setProductResponse] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [deleteProduct, setDeleteProduct] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [offerProductToEdit, setOfferProductToEdit] = useState(null);
  //const [showArchiveOfferProduct, setShowArchiveOfferProduct] = useState(false);
  const [selectedProductOptionType, setSelectedProductOptionType] = useState(
    OfferTypeDropdownItem[0]
  );

  const navigate = useNavigate();
  const { status, page: pageNum } = useParams();

  useEffect(() => {
    var optionType = OfferTypeDropdownItem[0];
    if (status !== undefined) {
      optionType = OfferTypeDropdownItem.find((s) => s.path === status);
    }

    setSelectedProductOptionType(optionType);

    getOfferProducts(optionType);
  }, [status, pageNum]);

  const getOfferProducts = async (
    selectedProductOptionType,
    page = pageNum
  ) => {
    if (isNaN(page)) {
      page = 0;
    }
    page = page - 1;
    if (page < 0) {
      page = 0;
    }

    try {
      var path = `offer-products`;
      if (selectedProductOptionType.path !== "") {
        path = `${path}/${selectedProductOptionType.path}`;
      }

      const response = await getRequest(`${path}?page=${page}`, true);
      setProducts(response.data);
      setProductResponse(response);
    } catch (err) {
      //console.log(err);
    }
  };

  /** dismiss the form modal */
  const dismissAddForm = (addedOfferProduct) => {
    setShowAddForm(false);
    setOfferProductToEdit(null);

    if (addedOfferProduct !== null) {
      getOfferProducts(
        selectedProductOptionType,
        productResponse?.metadata.currentPage ?? 0
      );
    }
  };

  const paginateToPage = (page) => {
    if (page != null && page !== productResponse?.metadata.currentPage) {
      getOfferProducts(selectedProductOptionType, page);
    }
  };

  const paginateToNextPage = () => {
    paginateToPage(productResponse.metadata.nextPage);
  };

  const paginateToPreviousPage = () => {
    paginateToPage(productResponse.metadata.previousPage);
  };

  const confirmDeleteOfferProduct = (deleteProduct) => {
    setDeleteProduct(deleteProduct);
    setShowDeleteConfirmation(true);
  };

  const deleteOfferProduct = async () => {
    try {
      const response = await deleteRequest("offer-products", deleteProduct.id);
      setAlertMessage(response.message);
      setShowDeleteConfirmation(false);
      setShowAlertDialog(true);

      getOfferProducts(
        selectedProductOptionType,
        productResponse?.metadata.currentPage ?? 0
      );
    } catch (err) {
      console.log(err);
    }
  };

  /** Handle Offer Product Update */
  const showEditForm = (selectedOfferProduct) => {
    setOfferProductToEdit(selectedOfferProduct);
    setShowAddForm(true);
  };

  /** Archive Offer Product */
  const handleArchiveOfferProduct = async (product) => {
    try {
      await patchRequest(`offer-products/${product.id}/active`, {
        active: !product.active,
      });

      getOfferProducts(
        selectedProductOptionType,
        productResponse?.metadata.currentPage ?? 0
      );
    } catch (err) {
      console.log(err);
    }
  };

  /** reset product list and product response */
  const clearOfferProducts = () => {
    setProducts([]);
    setProductResponse(null);
  };

  const showSelectedOfferProductType = (id) => {
    const offerType = OfferTypeDropdownItem.find((o) => o.id === id).path;
    if (offerType === "") {
      navigate("");
    } else {
      navigate(`${offerType}`);
    }
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white p-2">
        <div className="rounded-t mb-0 pl-2 py-3 pb-8 border-0">
          <SectionHeader>
            <SectionTitle title={"Offer Products"} />

            <PrimaryButton
              title={"Add New"}
              onClick={() => setShowAddForm(true)}
            />
          </SectionHeader>
        </div>

        <div className="mb-2 flex justify-end">
          <Dropdown
            options={OfferTypeDropdownItem}
            currentOption={selectedProductOptionType}
            onSelect={showSelectedOfferProductType}
          />
        </div>
        <OfferProductTable
          products={products}
          offset={paginationOffset(productResponse?.metadata)}
          onDelete={confirmDeleteOfferProduct}
          onEdit={showEditForm}
          onArchive={handleArchiveOfferProduct}
        />

        {productResponse?.metadata?.totalPages > 1 ? (
          <div className="py-2 flex justify-end my-6">
            <PaginatedView
              path={`/offer-products/${
                status !== undefined && status !== "" ? status + "/" : ""
              }page`}
              total={productResponse?.metadata.totalPages}
              current={productResponse?.metadata?.currentPage}
              next={productResponse?.metadata?.nextPage}
              previous={productResponse?.metadata?.previousPage}
            />
          </div>
        ) : null}
      </div>

      <FormModal
        hidden={!showAddForm}
        title={`${offerProductToEdit === null ? "Add" : "Edit"} Offer Products`}
        onDismiss={dismissAddForm}
      >
        <AddOfferProduct
          offerProduct={offerProductToEdit}
          onDismiss={dismissAddForm}
        />
      </FormModal>

      {deleteProduct != null ? (
        <ConfirmDialog
          title="Delete offer product?"
          message={`Are your sure you want to delete ${deleteProduct.name}?`}
          visible={showDeleteConfirmation}
          onAccept={deleteOfferProduct}
          onDismiss={() => setShowDeleteConfirmation(false)}
        />
      ) : null}

      <AlertDialog
        message={alertMessage}
        visible={showAlertDialog}
        onDismiss={() => setShowAlertDialog(false)}
      />
    </>
  );
}

export default OfferProduct;
