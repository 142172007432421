import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
  uploadRequest,
} from "../api";

// fetch news while handling for pagination
export const getCampaigns = async (archived, page) => {
  if (isNaN(page)) {
    page = 0;
  }
  page = page - 1;
  if (page < 0) {
    page = 0;
  }

  try {
    const path = "admin/campaign";
    const response = await getRequest(`${path}?page=${page}`, true);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getCampaign = async (campaignId) => {
  try {
    const path = `admin/campaign/${campaignId}`;
    const response = await getRequest(path, true);
    return response;
  } catch (err) {
    throw err;
  }
};

export const saveCampaign = async (formData, edit) => {
  try {
    var path = `admin/campaign`;
    const response = await uploadRequest(path, formData, edit);
    return response;
  } catch (err) {
    throw err;
  }
};

export const deleteCampaign = async (id) => {
  try {
    var path = `admin/campaign`;
    const response = await deleteRequest(path, id);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getCampaignPrizes = async (campaignId) => {
  try {
    const path = `admin/campaign/${campaignId}/prizes`;
    const response = await getRequest(path, true);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getCampaignPrize = async (prizeId) => {
  try {
    const path = `admin/campaign-prize/${prizeId}`;
    const response = await getRequest(path, true);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getCampaignPrizeUsers = async (prizeId, page, pageSize) => {
  try {
    const path = `admin/campaign-prize/${prizeId}/users?page=${page}&pageSize=${pageSize}`;
    const response = await getRequest(path, true);
    return response;
  } catch (err) {
    throw err;
  }
};

export const saveCampaignPrize = async (formData, edit) => {
  const response = await uploadRequest(`admin/campaign-prize`, formData, edit);
  return response;
};

export const deleteCampaignPrize = async (prizeId) => {
  try {
    var path = `admin/campaign-prize`;
    const response = await deleteRequest(path, prizeId);
    return response;
  } catch (err) {
    throw err;
  }
};

export const saveCampaignPrizeUsers = async (params) => {
  const response = await postRequest(
    "admin/campaign-prize/assign-users",
    params,
    true
  );
  return response;
};

export const bulkSaveCampaignPrizeUsers = async (formData, prizeId) => {
  const response = await uploadRequest(
    `admin/campaign-prize/${prizeId}/assign-users/upload`,
    formData,
    true
  );
  return response;
};

export const downloadCampaignCoupons = async (campaignId) => {
  const response = await getRequest(
    `admin/campaign-coupon/${campaignId}/export`,
    true,
    true
  );
  return response;
};

export const getUserCoupons = async (id) => {
  try {
    const path = `admin/user/${id}/coupon`;
    const response = await getRequest(path, true);
    return response;
  } catch (err) {
    throw err;
  }
};

export const setUserCouponUsed = async (couponCode) => {
  try {
    const path = `admin/campaign-coupon/${couponCode}/used`;
    const response = await patchRequest(path, null);
    return response;
  } catch (err) {
    throw err;
  }
};
