import React from "react";
import SectionTitle from "../section/SectionTitle";
import SectionHeader from "../section/SectionHeader";
import DestructiveButton from "../button/DestructiveButton";

function AlertDialog({ title, message, visible, onDismiss }) {
  return (
    <>
      <div
        className={`${
          visible ? "flex" : "hidden"
        } w-full bg-[#00000080] h-full absolute m-0 top-0 left-0 justify-center items-center`}
      >
        <div className="min-w-40 max-w-[90%] md:max-w-[60%] h-auto bg-white rounded-md p-4 overflow-auto">
          {title && title.length > 0 ? (
            <SectionHeader>
              <SectionTitle title={title} />
            </SectionHeader>
          ) : null}

          <div className="flex-auto p-2 mt-4 border-b-[1px] border-slate-300 mb-8 pb-6">
            <div>{message}</div>
          </div>

          <div className="mt-6 flex justify-end">
            <DestructiveButton title={"Close"} onClick={onDismiss} />
          </div>
        </div>
      </div>
    </>
  );
}

export default AlertDialog;
