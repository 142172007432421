import { Outlet, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import AuthContext from "../context/auth/AuthContext";
import Menu from "../components/menu/Menu";
import Navbar from "../components/navbar/Navbar";

const AppLayout = () => {
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (token === null || token === undefined) {
      navigate("/login");
    }
  });

  return (
    <div className="flex w-full">
      <Menu />

      <div className="grow">
        <Navbar />

        <div className="p-8">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
