import { CheckCircleIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";

function Dropdown({ options, currentOption, onSelect }) {
  const [showOptions, setShowOptions] = useState(false);

  const handleOptionSelect = (index) => {
    setShowOptions(false);
    onSelect(index);
  };

  return (
    <div className="relative">
      <button
        className="text-white uppercase text-sm px-4 py-2.5 text-right justify-between inline-flex items-center bg-green-700"
        type="button"
        onClick={() => setShowOptions((prev) => !prev)}
      >
        {currentOption.title}
        <svg
          className="w-2.5 h-2.5 ms-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>

      <div
        className={`z-10 absolute right-0 bg-green-700 divide-y mt-0.5 shadow-xl w-44 ${
          showOptions ? "" : "hidden"
        }`}
      >
        <ul className="text-xs text-white uppercase ">
          {options.map((opt) => (
            <li
              key={opt.id}
              className={`flex items-center justify-between px-3 py-2 gap-1 hover:bg-green-800 cursor-pointer border-green-800 border-b-[1px] ${
                opt.id === currentOption.id ? "bg-green-800" : ""
              }`}
              onClick={() => handleOptionSelect(opt.id)}
            >
              <span className="block py-2">{opt.title}</span>
              {opt.id === currentOption.id ? (
                <CheckCircleIcon className="w-4 h-4 text-green-400" />
              ) : null}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Dropdown;
