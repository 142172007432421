export const LOGIN_KEYS = {
  token: "token",
  user: "user",
};

export const saveLogin = (token, user) => {
  localStorage.setItem(LOGIN_KEYS.token, token);
  localStorage.setItem(LOGIN_KEYS.user, JSON.stringify(user));
};

export const getToken = () => {
  return localStorage.getItem(LOGIN_KEYS.token);
};

export const getUser = () => {
  const json = localStorage.getItem(LOGIN_KEYS.user);
  try {
    return JSON.parse(json);
  } catch (e) {
    return null;
  }
};

export const clearLogin = () => {
  localStorage.clear();
};
