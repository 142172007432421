import React from "react";
import { formatAppDate } from "../../../helpers/date-formatter";
import {
  ArrowDownTrayIcon,
  CloudArrowDownIcon,
  CloudArrowUpIcon,
  GiftIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import TableCell from "../../table/TableCell";

function CampaignTable({ campaigns, offset, onDelete, onArchive, isArchive }) {
  return (
    <>
      <table className="w-full min-w-[640px] table-auto">
        <thead>
          <tr>
            {[
              "sn",
              "starts on",
              "ends on",
              "title",
              "status",
              "image",
              "created on",
              "",
            ].map((el) => (
              <th
                key={el}
                className={`border-y bg-slate-50 border-blue-slate-50 py-3 px-5 text-left  ${
                  el === "status" ? "text-center" : ""
                }`}
              >
                <span
                  className={`text-[11px] font-bold uppercase text-blue-gray-400`}
                >
                  {el}
                </span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {campaigns.map((item, key) => {
            const {
              id,
              imageUrl,
              title,
              description,
              active,
              startsOn,
              endsOn,
              createdOn,
            } = item;

            return (
              <tr key={id}>
                <TableCell className="w-[60px]">
                  {offset && !offset.isNaN ? offset + key + 1 : key + 1}
                </TableCell>
                <TableCell className="w-32">
                  {formatAppDate(startsOn)}
                </TableCell>
                <TableCell className="w-32">{formatAppDate(endsOn)}</TableCell>
                <TableCell className="min-w-32">
                  <div className="flex flex-col gap-2">
                    <span>{title}</span>
                    <span className="mr-2 text-gray-500">{description}</span>
                  </div>
                </TableCell>
                <TableCell className="w-32">
                  <span
                    className={`block mx-auto w-3 h-3 shadow-md shadow-gray-300 rounded-full ${
                      active ? "bg-green-500" : "bg-red-500"
                    }`}
                    title={active ? "Active" : "Inactive"}
                  ></span>
                </TableCell>
                <TableCell className="w-28">
                  <img src={imageUrl} className="w-[70px] h-auto" />
                </TableCell>
                <TableCell className="w-32">
                  {formatAppDate(createdOn)}
                </TableCell>

                <TableCell className="w-36">
                  <div className="flex flex-row gap-2">
                    <span
                      className="text-yellow-600 cursor-pointer"
                      title="Campaign Prizes"
                    >
                      <Link to={`/coupon-campaign/${id}`}>
                        <GiftIcon className="w-4 h-4" />
                      </Link>
                    </span>
                    <span
                      className={`${
                        isArchive ? "text-green-400" : "text-orange-400"
                      } cursor-pointer`}
                      onClick={() => onArchive(item)}
                      title={`${isArchive ? "Activate" : "Archive"}`}
                    >
                      {isArchive ? (
                        <CloudArrowUpIcon className="w-4 h-4" />
                      ) : (
                        <CloudArrowDownIcon className="w-4 h-4" />
                      )}
                    </span>
                    <span className="text-blue-500 cursor-pointer" title="Edit">
                      <Link to={`/coupon-campaign/${id}/edit`}>
                        <PencilSquareIcon className="w-4 h-4" />
                      </Link>
                    </span>
                    <span
                      className="text-red-600 cursor-pointer"
                      title="Delete"
                    >
                      <Link to={`/coupon-campaign/${id}/delete`}>
                        <TrashIcon className="w-4 h-4" />
                      </Link>
                    </span>
                    <span
                      className="text-purple-500 cursor-pointer"
                      title="Download coupons"
                    >
                      <Link to={`/coupon-campaign/${id}/download`}>
                        <ArrowDownTrayIcon className="w-4 h-4" />
                      </Link>
                    </span>
                  </div>
                </TableCell>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default CampaignTable;
