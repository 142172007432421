import React, { useState, useEffect } from "react";
import { getUsers } from "../../../../services/users";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  saveCampaignPrizeUsers,
  getCampaignPrizeUsers,
  bulkSaveCampaignPrizeUsers,
} from "../../../../services/coupons/campaign";

import SectionHeader from "../../../../components/section/SectionHeader";
import SectionTitle from "../../../../components/section/SectionTitle";
import PrimaryButton from "../../../../components/button/PrimaryButton";
import SearchField from "../../../../components/search/SearchField";
import LoadingIcon from "../../../../assets/LoadingIcon";
import BulkAddCampaignUsers from "../../../../components/coupons/prizes/BulkAddCampaignUsers";
import { uploadRequest } from "../../../../services/api";

const PAGE_SIZE = 100;

function CouponPrizeAssignUsers() {
  const [bulkAdd, setBulkAdd] = useState(false);
  const [users, setUsers] = useState([]);
  const [metadata, setMetadata] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [saving, setSaving] = useState(false);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const { id: prizeId } = useParams();
  const [queryParams, setQueryParams] = useSearchParams();

  const navigate = useNavigate();

  useEffect(() => {
    fetch();
    fetchUsers();
  }, []);

  useEffect(() => {
    setBulkAdd(queryParams.has("bulk"));
  }, [queryParams]);

  const fetch = async () => {
    const prizeResponse = await getCampaignPrizeUsers(prizeId, 0, 50);
    if (prizeResponse.data.length > 0) {
      const existingPrizeUsers = prizeResponse.data.map((u) => `${u.userId}`);
      setSelectedUsers(existingPrizeUsers);
    }
  };

  const fetchUsers = async (page = 0, searchText = "") => {
    const response = await getUsers(page, PAGE_SIZE, searchText);

    if (page === 0) {
      // if first request, set contents of response data
      setUsers(response.data);
    } else {
      // append to exisitng data
      setUsers((prev) => [...prev, ...response.data]);
    }

    setMetadata(response.metadata);
  };

  const toggleSelectedUser = (userId) => {
    setSelectedUsers((prev) => {
      const value = `${userId}`;
      var updatedList = [...prev];

      const insertIndex = updatedList.indexOf(value);
      if (insertIndex === -1) {
        // list does not contain
        updatedList.push(value);
      } else {
        updatedList.splice(insertIndex, 1);
      }
      return updatedList;
    });
  };

  const handleSave = async () => {
    const params = [
      {
        prizeId: prizeId,
        users: selectedUsers.map((u) => parseInt(u)),
      },
    ];

    setSaving(true);

    try {
      const response = await saveCampaignPrizeUsers(params);
      //navigate(-1, { replace: true });
      setMessage(response.message);
      setTimeout(() => setMessage(null), 3000);
    } catch (err) {
      console.log(err);
    } finally {
      setSaving(false);
    }
  };

  const loadMoreUsers = () => {
    const nextPage = metadata.nextPage;

    if (nextPage === null) {
      return;
    }

    fetchUsers(nextPage);
  };

  const handleSearch = (text) => {
    setSelectedUsers([]);
    fetch();
    fetchUsers(0, text);
  };

  const handleClearSearch = () => {
    setSelectedUsers([]);
    fetch();
    fetchUsers();
  };

  const handleBulkSave = async (file) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("document", file);

      const response = await bulkSaveCampaignPrizeUsers(formData, prizeId);
      navigate(-1, { replace: true });
    } catch (err) {
      console.log("error");
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white p-2">
        {bulkAdd ? (
          <>
            <BulkAddCampaignUsers loading={loading} onSave={handleBulkSave} />
          </>
        ) : (
          <>
            <div className="rounded-t mb-0 px-4 py-3 pb-8 border-0">
              <SectionHeader>
                <SectionTitle title={"Assign Users"} />
                <div className="flex flex-row gap-2 align-middle items-center bg-red-200 justify-end">
                  <PrimaryButton
                    title={"Save"}
                    onClick={handleSave}
                    isBusy={saving}
                  />
                </div>
              </SectionHeader>
            </div>

            <div className="flex mb-4 w-full justify-end">
              <div className="flex justify-end items-end gap-2 w-full lg:w-1/3">
                <SearchField
                  onClear={handleClearSearch}
                  onSearch={handleSearch}
                />
              </div>
            </div>

            {message !== null ? (
              <div className="w-full lg:w-1/3 mx-4">
                <div className="text-blue-600 text-sm bg-blue-100 border-blue-200 border-[1px] rounded-sm p-2 mb-3">
                  {message}
                </div>
              </div>
            ) : null}

            <div className="mb-2 flex">
              {users?.length > 0 ? (
                <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-3 px-4 py-4 mb-4 rounded-lg">
                  {users.map((user) => (
                    <div
                      key={user.id}
                      className={`relative items-center mb-3  p-4 rounded-md shadow-sm ${
                        selectedUsers.indexOf(`${user.id}`) === -1
                          ? "bg-gray-100"
                          : "border-2 border-green-200 bg-green-50"
                      }`}
                      onClick={() => toggleSelectedUser(user.id)}
                    >
                      <div className="text-sm font-semibold text-gray-600">
                        <div className="mb-2">{user.username}</div>
                        <div>{user.id}</div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>

            {metadata && metadata.nextPage !== null ? (
              <div className="mb-2 flex justify-center">
                <div
                  className="bg-gray-200 hover:bg-gray-300 text-gray-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:text-gray-700 hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 cursor-pointer"
                  onClick={loadMoreUsers}
                >
                  Load More
                </div>
              </div>
            ) : null}
          </>
        )}
      </div>
    </>
  );
}

export default CouponPrizeAssignUsers;
