import React, { useEffect, useMemo, useState } from "react";
import { getRequest, postRequest } from "../../../services/api";
import SectionHeader from "../../../components/section/SectionHeader";
import SectionTitle from "../../../components/section/SectionTitle";
import {
  closestCorners,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useDraggable,
  useDroppable,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  rectSwappingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import OfferProductItemSortable from "../../../components/offer-products/OfferProductItemSortable";
import PrimaryButton from "../../../components/button/PrimaryButton";

function OfferProductSortOrder() {
  const [offerProducts, setOfferProducts] = useState([]);
  const [message, setMessage] = useState(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    getOfferProducts();
  }, []);

  const getOfferProducts = async () => {
    try {
      var path = `offer-products?pageSize=50`;
      const response = await getRequest(path, true);
      setOfferProducts(response.data);
    } catch (err) {
      //console.log(err);
    }
  };

  const getOfferProductIndex = (id) =>
    offerProducts.findIndex((o) => o.id === id);

  const handleDragEnd = (event) => {
    const { active, over } = event;

    // if element is dragged and dropped in the same position
    if (active.id === over.id) return;

    setOfferProducts((prev) => {
      // get the index of active product
      const origIndex = getOfferProductIndex(active.id);
      // get the index of over product: will be the index of the product after updating position
      const newIndex = getOfferProductIndex(over.id);

      // change the position of active and over products
      var updated = [...prev];
      return arrayMove(updated, origIndex, newIndex);
    });
  };

  const handleUpdate = async () => {
    const params = offerProducts.map((product, index) => ({
      productId: product.id,
      order: index + 1,
    }));

    try {
      const response = await postRequest("offer-products/order", params, true);
      setMessage(response.message);

      setTimeout(() => setMessage(null), 3000);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white p-2">
        <div className="rounded-t mb-0 pl-2 py-3 pb-8 border-0">
          <SectionHeader>
            <SectionTitle title={"Select Offer Products Ordering"} />
          </SectionHeader>

          <div className="w-full items-end align-middle text-right">
            <PrimaryButton title={"Update"} onClick={handleUpdate} />
          </div>
        </div>

        {message !== null && message.length > 0 ? (
          <div className="bg-blue-100 text-blue-700 text-sm p-4 mb-8 w-full border-blue-400 border-[0.5px] rounded-sm">
            {message}
          </div>
        ) : null}
        <DndContext
          collisionDetection={closestCorners}
          onDragEnd={handleDragEnd}
          sensors={sensors}
        >
          <div className="mb-2 flex">
            <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 bg-gray-100 px-4 py-4 mb-4 rounded-lg">
              <SortableContext
                items={offerProducts}
                strategy={rectSwappingStrategy}
              >
                {offerProducts.map((o) => (
                  <OfferProductItemSortable key={o.id} product={o} />
                ))}
              </SortableContext>
            </div>
          </div>
        </DndContext>
      </div>
    </>
  );
}

export default OfferProductSortOrder;
