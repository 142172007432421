import {
  CloudArrowDownIcon,
  CloudArrowUpIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";
import { formatAppDate } from "../../helpers/date-formatter";

function SliderTable({ sliders, offset }) {
  return (
    <>
      <table className="w-full min-w-[640px] table-auto">
        <thead>
          <tr>
            {["sn", "image", "title", "active", "order", "created_on", ""].map(
              (el) => (
                <th
                  key={el}
                  className="border-y bg-slate-50 border-slate-50 py-3 px-5 text-left"
                >
                  <span className="text-[11px] font-bold uppercase text-slate-400">
                    {el}
                  </span>
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody>
          {sliders.map((slider, key) => {
            const {
              id,
              imageUrl,
              title,
              description,
              active,
              position,
              createdOn,
            } = slider;

            return (
              <tr key={id}>
                <td className="py-3 px-5 border-b border-blue-gray-50 w-[60px]">
                  <span className="text-xs font-semibold text-blue-gray-600">
                    {offset && !offset.isNaN ? offset + key + 1 : key + 1}
                  </span>
                </td>
                <td className="py-3 px-5 border-b border-blue-gray-50 w-[200px]">
                  <span className="text-xs font-semibold text-blue-gray-600">
                    <img src={imageUrl} className="w-auto h-auto" />
                  </span>
                </td>
                <td className="py-3 px-5 border-b border-blue-gray-50">
                  <div className="flex flex-col gap-2">
                    <span className="text-xs font-semibold text-blue-gray-600">
                      {title}
                    </span>
                    <span className="text-xs font-normal text-blue-gray-600">
                      {description}
                    </span>
                  </div>
                </td>
                <td className="py-3 px-5 border-b border-blue-gray-50 w-32">
                  <span
                    className={`block mx-auto w-3 h-3 shadow-md shadow-gray-300 rounded-full text-center ${
                      active ? "bg-green-500" : "bg-red-500"
                    }`}
                    title={active ? "Active" : "Inactive"}
                  ></span>
                </td>
                <td className="py-3 px-5 border-b border-blue-gray-50 w-32">
                  <span className="text-xs font-semibold text-blue-gray-600">
                    {position}
                  </span>
                </td>
                <td className="py-3 px-5 border-b border-blue-gray-50 w-32">
                  <span className="text-xs font-semibold text-blue-gray-600">
                    {formatAppDate(createdOn)}
                  </span>
                </td>
                <td className="py-3 px-5 border-b border-blue-gray-50 text-right w-32">
                  <div className=" flex flex-row gap-2">
                    <span
                      className="text-xs font-semibold text-blue-500 cursor-pointer"
                      title="Edit"
                    >
                      <Link to={`/slider/${id}/edit`}>
                        <PencilSquareIcon className="w-4 h-4" />
                      </Link>
                    </span>
                    <span
                      className="text-xs font-semibold  text-red-600 cursor-pointer"
                      title="Delete"
                    >
                      <Link to={`/slider/${id}/delete`}>
                        <TrashIcon className="w-4 h-4" />
                      </Link>
                    </span>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default SliderTable;
