import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import React from "react";
import { NavLink } from "react-router-dom";

function NotFound() {
  return (
    <div className="relative w-full h-full p-8 min-h-screen bg-slate-200">
      <div className="">
        <NavLink
          to={"/"}
          className="flex flex-row gap-2 items-center font-semibold text-green-600 my-4"
        >
          <ArrowLeftIcon className="w-4 h-4" />
          Go Back
        </NavLink>
      </div>

      <h1 className="text-2xl mt-4">404 Not Found</h1>
    </div>
  );
}

export default NotFound;
