import React from "react";
import { formatAppDate } from "../../helpers/date-formatter";
import { TicketIcon } from "@heroicons/react/16/solid";
import { Link } from "react-router-dom";

function UsersTable({ users, offset }) {
  return (
    <>
      <table className="w-full min-w-[640px] table-auto">
        <thead>
          <tr>
            {["sn", "name", "id", "username", "created on", ""].map((el) => (
              <th
                key={el}
                className="border-y bg-slate-50 border-blue-slate-50 py-3 px-5 text-left"
              >
                <span className="text-[11px] font-bold uppercase text-blue-gray-400">
                  {el}
                </span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {users.map((user, key) => {
            const { id, username, userInfo } = user;
            const { firstName, lastName, registeredOn, qrcodeUrl } = userInfo;

            return (
              <tr key={id}>
                <td className="py-3 px-5 border-b border-blue-gray-50 w-[60px]">
                  <span className="text-xs font-semibold text-blue-gray-600">
                    {offset && !offset.isNaN ? offset + key + 1 : key + 1}
                  </span>
                </td>
                <td className="py-3 px-5 border-b border-blue-gray-50 min-w-32">
                  <div className="flex flex-col gap-2">
                    <span className="text-xs font-semibold text-blue-gray-600">
                      {[firstName, lastName].join(" ")}
                    </span>
                  </div>
                </td>
                <td className="py-3 px-5 border-b border-blue-gray-50">
                  <span className="text-xs font-semibold text-blue-gray-600">
                    {id}
                  </span>
                </td>
                <td className="py-3 px-5 border-b border-blue-gray-50 min-w-32">
                  <div className="flex flex-col gap-2">
                    <span className="text-xs font-semibold text-blue-gray-600">
                      {username}
                    </span>
                  </div>
                </td>
                <td className="py-3 px-5 border-b border-blue-gray-50 w-32">
                  <span className="text-xs font-semibold text-blue-gray-600">
                    {formatAppDate(registeredOn)}
                  </span>
                </td>
                <td className="py-3 px-5 border-b border-blue-gray-50 w-32">
                  <div className=" flex flex-row gap-2">
                    <Link to={`/users/${id}/coupons`}>
                      <span
                        className="text-xs font-semibold text-green-400 cursor-pointer"
                        title="coupons"
                      >
                        <TicketIcon className="w-4 h-4" />
                      </span>
                    </Link>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default UsersTable;
