import React, { useState } from "react";

import SectionHeader from "../../section/SectionHeader";
import SectionTitle from "../../section/SectionTitle";
import LoadingIcon from "../../../assets/LoadingIcon";
import { DocumentIcon, DocumentTextIcon } from "@heroicons/react/24/outline";

function BulkAddCampaignUsers({ onSave, loading }) {
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const handleSelectedFile = (e) => {
    setFile(e.target.files[0]);
    setPreviewUrl(URL.createObjectURL(e.target.files[0]));
  };

  const handleSave = () => {
    onSave(file);
  };

  return (
    <>
      <div className="rounded-t mb-0 px-4 py-3 pb-8 border-0">
        <SectionHeader>
          <SectionTitle title={"Upload Users"} />
        </SectionHeader>
      </div>

      <div className="mb-2 flex">
        <form className="w-full">
          <div className="flex flex-wrap p-4">
            <div className="relative w-full lg:w-1/2 mb-3 flex flex-col">
              <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2 mr">
                News Image<span>*</span>
              </label>
              <div className="flex gap-2 items-center">
                <input
                  accept="text/csv"
                  type="file"
                  className="border-0 px-3 py-3 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  onChange={handleSelectedFile}
                />

                {previewUrl != null ? (
                  <DocumentTextIcon className="w-6 h-6" />
                ) : null}
              </div>
            </div>
          </div>

          <div className="w-full px-4 mb-2 mt-2 flex-1 justify-end">
            <div className="relative w-full mb-3">
              <button
                className="flex gap-1 items-center bg-lightBlue-500 text-white bg-green-800 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none disabled:bg-gray-400 mr-1 ease-linear transition-all duration-150"
                type="button"
                disabled={loading}
                onClick={handleSave}
              >
                {loading ? <LoadingIcon /> : null}
                <span>Upload</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default BulkAddCampaignUsers;
