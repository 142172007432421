import React, { useEffect, useState } from "react";
import SectionHeader from "../../../components/section/SectionHeader";
import SectionTitle from "../../../components/section/SectionTitle";
import PrimaryButton from "../../../components/button/PrimaryButton";
import NewsTable from "../../../components/news/NewsTable";
import PaginationView from "../../../components/pagination/PaginationView";
import FormModal from "../../../components/modal/FormModal";
import AddNews from "./AddNews";
import ConfirmDialog from "../../../components/dialog/ConfirmDialog";
import AlertDialog from "../../../components/dialog/AlertDialog";
import { getRequest, deleteRequest, patchRequest } from "../../../services/api";
import { paginationOffset } from "../../../helpers/pagination";
import { useNavigate, useParams } from "react-router-dom";
import PaginatedView from "../../../components/pagination/PaginatedView";

function News() {
  const [news, setNews] = useState([]);
  const [newsResponse, setNewsResponse] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [deleteNews, setDeleteNews] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [newsToEdit, setNewsToEdit] = useState(null);
  const [showArchiveNews, setShowArchiveNews] = useState(false);

  const navigate = useNavigate();
  const { page: pageNum, status } = useParams();

  useEffect(() => {
    var showArchived = false;
    if (status != undefined && status == "inactive") {
      showArchived = true;
    }
    setShowArchiveNews(showArchived);
    getNews(showArchived);
  }, [status, pageNum]);

  // fetch news while handling for pagination
  const getNews = async (archived, page = pageNum) => {
    if (isNaN(page)) {
      page = 0;
    }
    page = page - 1;
    if (page < 0) {
      page = 0;
    }

    try {
      const path = archived ? "news/inactive" : "news";

      const response = await getRequest(`${path}?page=${page}`, true);
      setNews(response.data);
      setNewsResponse(response);
    } catch (err) {
      //console.log(err);
    }
  };

  /* Pagination Helpers */
  const paginateToPage = (page) => {
    if (page != null && page !== newsResponse?.metadata.currentPage) {
      getNews(showArchiveNews, page);
    }
  };

  const paginateToNextPage = () => {
    paginateToPage(newsResponse.metadata.nextPage);
  };

  const paginateToPreviousPage = () => {
    paginateToPage(newsResponse.metadata.previousPage);
  };

  // dismiss the form modal
  const dismissAddForm = (addedNews) => {
    setShowAddForm(false);
    setNewsToEdit(null);

    if (addedNews !== null) {
      getNews(showArchiveNews, newsResponse?.metadata.currentPage ?? 0);
    }
  };

  /* Delete News */
  const confirmDeleteNews = (deleteNews) => {
    setDeleteNews(deleteNews);
    setShowDeleteConfirmation(true);
  };

  const handleDeleteNews = async () => {
    try {
      const response = await deleteRequest("news", deleteNews.id);
      setAlertMessage(response.message);
      setShowDeleteConfirmation(false);
      setShowAlertDialog(true);

      getNews(showArchiveNews, newsResponse?.metadata.currentPage ?? 0);
    } catch (err) {
      console.log(err);
    }
  };

  /** Handle News Update */
  const showEditForm = (selectedNews) => {
    setNewsToEdit(selectedNews);
    setShowAddForm(true);
  };

  /** Archive news */
  const handleArchiveNews = async (newsItem) => {
    try {
      await patchRequest(`news/${newsItem.id}/active`, {
        active: !newsItem.active,
      });

      getNews(showArchiveNews, newsResponse?.metadata.currentPage ?? 0);
    } catch (err) {
      console.log(err);
    }
  };

  /** Toggle archived/active news list */
  const toggleShowArchiveNews = () => {
    const updatedStatus = !showArchiveNews;
    console.log(updatedStatus);

    navigate(updatedStatus ? "inactive" : "");
    //setShowArchiveNews(updatedStatus);
    // clearNews();
    // getNews(updatedStatus);
  };

  /** reset news list and news response */
  const clearNews = () => {
    setNews([]);
    setNewsResponse(null);
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white p-2">
        <div className="rounded-t mb-0 px-4 py-3 pb-8 border-0">
          <SectionHeader>
            <SectionTitle title={"News"} />

            <PrimaryButton
              title={"Add New"}
              onClick={() => setShowAddForm(true)}
            />
          </SectionHeader>
        </div>

        <div className="flex gap-2 items-center mb-3">
          <input
            type="checkbox"
            className="form-checkbox border-0 rounded ml-1 w-4 h-4 cursor-pointer"
            checked={showArchiveNews}
            onChange={toggleShowArchiveNews}
          />
          <span className="text-xs font-bold uppercase text-amber-500">
            Show Archived
          </span>
        </div>

        <NewsTable
          news={news}
          offset={paginationOffset(newsResponse?.metadata)}
          onDelete={confirmDeleteNews}
          onEdit={showEditForm}
          onArchive={handleArchiveNews}
          isArchive={showArchiveNews}
        />

        {newsResponse?.metadata?.totalPages > 1 ? (
          <div className="py-2 flex justify-end my-6">
            <PaginatedView
              path={`/news/${
                status !== undefined && status !== "" ? status + "/" : ""
              }page`}
              total={newsResponse?.metadata.totalPages}
              current={newsResponse?.metadata?.currentPage}
              next={newsResponse?.metadata?.nextPage}
              previous={newsResponse?.metadata?.previousPage}
            />
          </div>
        ) : null}
      </div>

      <FormModal
        hidden={!showAddForm}
        title={`${newsToEdit == null ? "Add" : "Edit"} News`}
        onDismiss={dismissAddForm}
      >
        <AddNews news={newsToEdit} onDismiss={dismissAddForm} />
      </FormModal>

      {deleteNews != null ? (
        <ConfirmDialog
          title="Delete News?"
          message={`Are your sure you want to delete ${deleteNews.title}?`}
          visible={showDeleteConfirmation}
          onAccept={handleDeleteNews}
          onDismiss={() => setShowDeleteConfirmation(false)}
        />
      ) : null}

      <AlertDialog
        message={alertMessage}
        visible={showAlertDialog}
        onDismiss={() => setShowAlertDialog(false)}
      />
    </>
  );
}

export default News;
