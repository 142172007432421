import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PaginationItem from "./PaginationItem";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

function PaginatedView({ path, query, current, total, next, previous }) {
  const [queryParam, setQueryParam] = useState("");

  useEffect(() => {
    if (query === undefined) {
      query = "";
    } else {
      setQueryParam(`?${query}`);
    }
  }, [query]);

  return (
    <>
      <nav className="block">
        <ul className="flex pl-0 rounded list-none flex-wrap">
          <li>
            <Link
              to={`${path}/${
                previous != null ? previous + 1 : current + 1
              }${queryParam}`}
            >
              <PaginationItem>
                <ChevronLeftIcon
                  className={`${
                    previous === null ? "text-gray-200" : "text-current"
                  } w-4 h-4`}
                />
              </PaginationItem>
            </Link>
          </li>
          {[...Array(total)].map((_, index) => (
            <li key={index}>
              <Link to={`${path}/${index + 1}${queryParam}`}>
                <PaginationItem
                  active={current == index}
                  activeColor={"bg-green-600"}
                  activeTextColor={"text-white"}
                >
                  {index + 1}
                </PaginationItem>
              </Link>
            </li>
          ))}
          <li>
            <Link
              to={`${path}/${
                next != null ? next + 1 : current + 1
              }${queryParam}`}
            >
              <PaginationItem>
                <ChevronRightIcon
                  className={`${
                    next === null ? "text-gray-200" : "text-current"
                  } w-4 h-4`}
                />
              </PaginationItem>
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default PaginatedView;
