import React, { useContext, useEffect } from "react";
import AuthContext from "../../context/auth/AuthContext";

function Logout() {
  const { logoutUser } = useContext(AuthContext);

  useEffect(() => {
    logoutUser();
  }, []);

  return <></>;
}

export default Logout;
