import React, { useEffect, useState } from "react";
import { uploadRequest } from "../../services/api";
import { isEmpty } from "../../helpers/validator";
import LoadingIcon from "../../assets/LoadingIcon";
import { formatAppDate } from "../../helpers/date-formatter";

function AddOfferProduct({ offerProduct, onDismiss }) {
  const [name, setName] = useState("");
  const [validUntil, setValidUntil] = useState("");
  const [price, setPrice] = useState("");
  const [offerPrice, setOfferPrice] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [description, setDescription] = useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);

  useEffect(() => {
    setErrorMessage([]);
    if (offerProduct != null) {
      setName(offerProduct.name);
      setPrice(offerProduct.price.toString());
      setOfferPrice(offerProduct.offerPrice.toString());
      setDescription(offerProduct.description);
      setImagePreviewUrl(offerProduct.imageUrl);
      setValidUntil(formatAppDate(offerProduct.validUntil));
    } else {
      setName("");
      setPrice("");
      setOfferPrice("");
      setDescription("");
      setImagePreviewUrl(null);
      setValidUntil("");
      setImageFile(null);
    }
  }, [offerProduct]);

  const handleSelectedImage = (e) => {
    setImageFile(e.target.files[0]);
    setImagePreviewUrl(URL.createObjectURL(e.target.files[0]));
  };

  const handleSave = async () => {
    setErrorMessage(null);

    let errors = [];
    if (isEmpty(name)) {
      errors.push("Please enter the product name.");
    }
    if (isEmpty(validUntil)) {
      errors.push("Please enter the date until the offer is valid.");
    }
    if (isEmpty(price)) {
      errors.push("Please enter the price of the product.");
    }
    if (isEmpty(offerPrice)) {
      errors.push("Please enter the offer price of the product.");
    }
    if (offerProduct === null && imageFile == null) {
      errors.push("Please select the product image.");
    }

    if (errors.length > 0) {
      setErrorMessage(errors);
      return;
    }

    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("image", imageFile);
      formData.append("name", name);
      formData.append("price", price);
      formData.append("offerPrice", offerPrice);
      formData.append("active", true);
      formData.append("validUntil", validUntil);

      const response = await uploadRequest(
        `offer-products${offerProduct !== null ? `/${offerProduct.id}` : ""}`,
        formData,
        offerProduct !== null
      );
      onDismiss(response);
    } catch (err) {
      setErrorMessage([err.message]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex-auto p-4 py-8 mt-8 bg-purple-50">
        <form>
          {errorMessage !== null && errorMessage.length > 0 ? (
            <div className="bg-red-100 text-red-700 text-sm p-4 mx-4 mb-8 border-red-400 border-[0.5px] rounded-sm">
              <ul>
                {errorMessage.map((msg, idx) => (
                  <li key={idx}>{msg}</li>
                ))}
              </ul>
            </div>
          ) : null}
          <div className="flex flex-wrap">
            <div className="w-full lg:w-6/12 px-4 mb-2">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Name<span>*</span>
                </label>
                <input
                  type="text"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  value={name || ""}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4 mb-2">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Valid Until<span>*</span>
                </label>
                <input
                  type="date"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  value={validUntil || ""}
                  onChange={(e) => setValidUntil(e.target.value)}
                />
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4 mb-2">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Regular Price<span>*</span>
                </label>
                <input
                  type="text"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  value={price || ""}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4 mb-2">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Offer Price<span>*</span>
                </label>
                <input
                  type="text"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  value={offerPrice || ""}
                  onChange={(e) => setOfferPrice(e.target.value)}
                />
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4 mb-2">
              <div className="relative w-full mb-3 flex flex-col">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2 mr">
                  Product Image<span>*</span>
                </label>
                <div className="flex gap-2 items-center">
                  {imagePreviewUrl != null ? (
                    <img
                      src={imagePreviewUrl}
                      className="w-10 h-auto rounded-md"
                    />
                  ) : null}
                  <input
                    accept="image/*"
                    type="file"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={handleSelectedImage}
                  />
                </div>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4 mb-2">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Description
                </label>
                <textarea
                  type="text"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  rows="4"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description || ""}
                ></textarea>
              </div>
            </div>
            <div className="w-full px-4 mb-2 mt-4 flex-1 justify-end">
              <div className="relative w-full mb-3">
                <button
                  className="flex gap-1 items-center bg-lightBlue-500 text-white bg-green-800 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none disabled:bg-gray-400 mr-1 ease-linear transition-all duration-150"
                  type="button"
                  disabled={loading}
                  onClick={handleSave}
                >
                  {loading ? <LoadingIcon /> : null}
                  <span>{`${offerProduct === null ? "Save" : "Update"}`}</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default AddOfferProduct;
