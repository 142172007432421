import React from "react";

const PaginationItem = ({ children, active, activeColor, activeTextColor }) => {
  let className =
    "first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 cursor-pointer ";
  if (active === true) {
    className += ` ${activeColor !== undefined ? activeColor : "bg-slate-300"}`;
    className += ` ${
      activeTextColor !== undefined ? activeTextColor : "text-gray-600"
    }`;
  } else {
    className += " bg-white text-gray-600";
  }
  return <span className={className}>{children}</span>;
};

export default PaginationItem;
