import React from "react";
import TableCell from "../../table/TableCell";
import { formatAppDate } from "../../../helpers/date-formatter";

const headers = [
  "username",
  "user id",
  "coupon code",
  "coupon status",
  "used on",
];

function CampaignPrizeUserTable({ prizeUsers, offset }) {
  return (
    <>
      <table className="w-full min-w-[640px] table-auto">
        <thead>
          <tr>
            {headers.map((el) => (
              <th
                key={el}
                className={`border-y bg-slate-50 border-slate-50 py-3 px-5 text-left  ${
                  el === "coupon status" ? "text-center" : ""
                }`}
              >
                <span
                  className={`text-[11px] font-bold uppercase text-gray-400`}
                >
                  {el}
                </span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {prizeUsers.map((item) => {
            const {
              userId,
              username,
              couponActive,
              couponCode,
              couponId,
              usedOn,
            } = item;

            return (
              <tr key={userId}>
                <TableCell>{username}</TableCell>
                <TableCell className="w-32">{userId}</TableCell>
                <TableCell className="w-32">{couponCode}</TableCell>
                <TableCell className="w-64 text-center">
                  <span
                    className={`block mx-auto w-3 h-3 shadow-md shadow-gray-300 rounded-full ${
                      couponActive ? "bg-green-500" : "bg-red-500"
                    }`}
                    title={couponActive ? "Active" : "Inactive"}
                  ></span>
                </TableCell>
                <TableCell className="w-32">
                  {usedOn !== null ? formatAppDate(usedOn) : "-"}
                </TableCell>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default CampaignPrizeUserTable;
