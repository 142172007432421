import React from "react";
import { formatAppDate } from "../../helpers/date-formatter";
import ArchiveIcon from "../../assets/ArchiveIcon";
import EditIcon from "../../assets/EditIcon";
import DeleteIcon from "../../assets/DeleteIcon";
import ActivateIcon from "../../assets/ActivateIcon";

function NewsTable({ news, offset, onDelete, onEdit, onArchive, isArchive }) {
  return (
    <>
      <table className="w-full min-w-[640px] table-auto">
        <thead>
          <tr>
            {["sn", "name", "image", "created on", ""].map((el) => (
              <th
                key={el}
                className="border-y bg-slate-50 border-blue-slate-50 py-3 px-5 text-left"
              >
                <span className="text-[11px] font-bold uppercase text-blue-gray-400">
                  {el}
                </span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {news.map((newsItem, key) => {
            const { id, imageUrl, title, content, createdOn } = newsItem;

            return (
              <tr key={id}>
                <td className="py-3 px-5 border-b border-blue-gray-50 w-[60px]">
                  <span className="text-xs font-semibold text-blue-gray-600">
                    {offset && !offset.isNaN ? offset + key + 1 : key + 1}
                  </span>
                </td>
                <td className="py-3 px-5 border-b border-blue-gray-50 min-w-32">
                  <div className="flex flex-col gap-2">
                    <span className="text-xs font-semibold text-blue-gray-600">
                      {title}
                    </span>
                    <span className="text-xs text-blue-gray-600 mr-2">
                      {content}
                    </span>
                  </div>
                </td>
                <td className="py-3 px-5 border-b border-blue-gray-50 w-28">
                  <span className="text-xs font-semibold text-blue-gray-600">
                    <img src={imageUrl} className="w-[70px] h-auto" />
                  </span>
                </td>
                <td className="py-3 px-5 border-b border-blue-gray-50 w-32">
                  <span className="text-xs font-semibold text-blue-gray-600">
                    {formatAppDate(createdOn)}
                  </span>
                </td>
                <td className="py-3 px-5 border-b border-blue-gray-50 text-right w-32">
                  <div className="flex flex-row gap-2">
                    <span
                      className={`text-xs font-semibold ${
                        isArchive ? "text-green-400" : "text-orange-400"
                      } cursor-pointer`}
                      onClick={() => onArchive(newsItem)}
                      title={`${isArchive ? "Activate" : "Archive"}`}
                    >
                      {isArchive ? <ActivateIcon /> : <ArchiveIcon />}
                    </span>
                    <span
                      className="text-xs font-semibold text-blue-500 cursor-pointer"
                      onClick={() => onEdit(newsItem)}
                      title="Edit"
                    >
                      <EditIcon />
                    </span>
                    <a
                      className="text-xs font-semibold text-red-600 cursor-pointer"
                      onClick={() => onDelete(newsItem)}
                      title="Delete"
                    >
                      <DeleteIcon />
                    </a>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default NewsTable;
